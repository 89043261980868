import React, { useEffect, useState, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import NFTShareSection from './NFTShareSection';
import moment from 'moment';
import NImage from '../NImage';
import { NFTSpine } from '../NFTSpine';
import { HERO_COLLECTIONS } from '../../constant/constant';
import { useParams } from 'react-router-dom';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../../config';

interface Props {
    data: any;
    isMine: boolean;
    skillData?: any;
    setSkillData?: (data: any) => void;
}

function NFTImage({ data, isMine, skillData, setSkillData }: Props) {
    return (
        <div className="detail-card-image">
            {/* <div className={get(data, 'metadata.heroId', 1) === 12 ? "detail-item-bg-quanvu" : "detail-item-bg"} style={{ backgroundImage: `url(/media/images/vongnguhanh/${ELEMENT_MAPPING[get(data, 'metadata.fiveElementId', 1)]})` }}></div> */}
            <div className="detail-item-tag">
                <img src={``} />
                <span>{}</span>
            </div>
            <NFTSpine nftId={get(data, 'attributes[0].value', 0)} className="position-relative">
                <div
                    className="player-container-bg"
                    // style={{
                    //     backgroundImage: `url('https://mymasterwar-bk.s3.ap-southeast-1.amazonaws.com/generals-v3/008/008_2_1_4_2_0_1_0_4.gif?v=nZTzmCa33Z)?v=v9EKQHP1Vf')`,
                    // }}
                ></div>
            </NFTSpine>
            <div className="detail-item-creator">
                <div className="detail-item-info mb-4">
                    {/* <NImage className="detail-item-icon" src={`/soliderType/.png`} alt="##" /> */}
                    {/* <NImage className="detail-item-icon" src={ELEMENTS_ICONS[get(data, 'metadata.fiveElementId', 1) - 1]} alt="##" /> */}
                    <div className="detail-item-name">
                        <strong>{get(HERO_COLLECTIONS, `[${get(data, 'attributes[0].value')}].name`, '')}</strong>
                    </div>
                </div>
                <div className="detail-item-description">
                    {get(HERO_COLLECTIONS, `[${get(data, 'attributes[0].value')}].description`, '')}
                </div>
                <NFTShareSection url={window.location.href} />

                <div className="detail-item-other mt-lg-3 mt-3 mb-2">
                    <div className="row">
                        <div className="col-md-9 mt-2 mt-lg-0">
                            <div className="detail-item-meta text-md-start text-center">
                                <i className="far fa-user me-2"></i>
                                {isMine ? (
                                    <a
                                        target="_blank"
                                        href={``}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="(ronin:5facc0b38480350be74e54766e6738e9fab8edc5)"
                                    >
                                        {}
                                    </a>
                                ) : (
                                    <a
                                        target="_blank"
                                        href={`${ENV_CONFIGS.EXPLORER_URL}/${EXPLORER_TYPES.ADDRESS}/${get(
                                            data,
                                            'owner',
                                            '',
                                        )}?chainid=${ENV_CONFIGS.CHAIN_ID}`}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="(ronin:5facc0b38480350be74e54766e6738e9fab8edc5)"
                                    >
                                        {get(data, 'owner', '')}
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 mt-2 mt-md-0">
                            <div className="detail-item-meta text-md-end text-center">
                                <i className="far fa-clock me-2"></i>
                                <span>{moment(get(data, 'createdAt', 0)).fromNow()}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NFTImage;
