import React from 'react';
import NImage from '../NImage';

interface Props {
    src: string;
    label: string;
    value: number | string;
}

function NFTPropertyItem({ src, label, value }: Props) {
    return (
        <div className="nft-infor-line">
            <div className="d-flex align-items-center">
                <NImage width={'24px'} src={src} alt="" />
                <h6 className="text-white mb-0 fw-bold ms-2">{label}</h6>
            </div>
            <b className="text-white mb-0 fw-bold" style={{ marginLeft: 20 }}>
                {value}
            </b>
        </div>
    );
}
export default NFTPropertyItem;
