import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { get } from 'lodash';
import OHTTooltip from '../../components/Tooltip';
import { SUPPORT_SPINE_HERO } from '../../constant/constant';

interface Props {
    data: any;
    handleSkillSelected: (data: any) => void;
}

function NFTAbilities({ data, handleSkillSelected }: Props) {
    let nftId = get(data, 'attributes[0].value', 0);
    if (!SUPPORT_SPINE_HERO.includes(nftId)) {
        nftId = 1000;
    }
    return (
        <div className="detail-card abilities-card mt-5 mb-5 px-3 pb-0">
            <div className="detail-card-title">
                <span>Abilities</span>
            </div>
            <div className="detail-card-body">
                <Row className="mt-3">
                    {get(data, 'attributes[2].value', '')
                        .split(',')
                        .map((item: any, index: number) => {
                            if (item) {
                                return (
                                    <Col xs="4" key={index + 'ability'}>
                                        <OHTTooltip tooltip={item?.trim()}>
                                            <div
                                                onClick={() => {
                                                    if (handleSkillSelected) {
                                                        handleSkillSelected(item);
                                                    }
                                                }}
                                                className="abilities-item"
                                            >
                                                <div className="abilities-item-icon">
                                                    <img
                                                        src={`/assets/images/skill/hero_skill_${nftId}0${
                                                            index + 1
                                                        }.png`}
                                                    />
                                                </div>

                                                <span>{item?.trim()}</span>
                                            </div>
                                        </OHTTooltip>
                                    </Col>
                                );
                            }
                        })}
                </Row>
            </div>
        </div>
    );
}
export default NFTAbilities;
