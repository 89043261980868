import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Tab, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/section-title/SectionTitle';
import MyAccount from './components/my-account/MyAccount';
import Inventory from './components/inventory/Inventory';
import Button from '../../components/button/Button';
import BuyPackage from './components/buy-package/BuyPackage';
import { useWeb3React } from '@web3-react/core';

function AccountPage() {
    const { account } = useWeb3React();
    //   const matBalance = useMatBalance();
    //   const history = useHistory();

    //   const dispatch = useDispatch();

    //   const nftData = useSelector(state => get(state, 'user.nfts', []));

    //   useEffect(() => {
    //     if (account) {
    //       dispatch(requestFetchNftsByAddress(account));
    //     }
    //   }, []);

    //   const handleCopyAddress = () => {
    //     setCopied(true);
    //     setTimeout(() => {
    //       setCopied(false);
    //     }, 1000);
    //   }

    //   const handleDirect = () => {
    //     window.open(`${BSC_SCAN_URL[chainId]}${BSC_SCAN_TYPE['ADDRESS']}${account}`);
    //   }

    return (
        <div className="my-account-page">
            <SectionTitle mb="20px">My Account</SectionTitle>
            <Tabs defaultActiveKey="account" id="uncontrolled-tab-example" className="mb-4">
                <Tab eventKey="account" title="Account">
                    <MyAccount />
                </Tab>
                {account && (
                    <Tab eventKey="inventory" title="Inventory">
                        <Inventory />
                    </Tab>
                )}
                {account && (
                    <Tab eventKey="package" title="Buy Package">
                        <BuyPackage />
                    </Tab>
                )}
            </Tabs>
        </div>
    );
}

export default AccountPage;
