import { CSSProperties, ReactNode } from 'react';
import leftDividerImg from '../../assets/images/left-divider.png';
import rightDividerImg from '../../assets/images/right-divider.png';
import './SectionTitle.scss';

export interface SectionTitleProps {
    size?: 'sm';
    centered?: boolean;
    hideDivider?: boolean;
    dividerInline?: boolean;
    children?: ReactNode;
    mb?: string;
    className?: string;
    style?: CSSProperties;
}
const SectionTitle = ({
    size,
    centered,
    hideDivider,
    dividerInline,
    children,
    mb,
    className,
    style,
}: SectionTitleProps) => {
    return (
        <div
            className={`section-title ${size ?? ''} ${className ?? ''} ${centered ? 'center' : ''} ${
                dividerInline ? 'divider-inline' : ''
            }`}
            style={{
                ...style,
                marginBottom: mb,
            }}
        >
            <h3>
                {centered && !hideDivider ? <img className="divider" src={leftDividerImg} alt="divider" /> : <></>}
                <span>{children}</span>
                {(centered || dividerInline) && !hideDivider ? (
                    <img className="divider" src={rightDividerImg} alt="divider" />
                ) : (
                    <></>
                )}
            </h3>
            {!dividerInline && !hideDivider ? <img className="divider" src={rightDividerImg} alt="divider" /> : <></>}
        </div>
    );
};
export default SectionTitle;
