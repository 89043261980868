import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './GeneralCard.scss';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES, CURRENCY_NAME_MAPPING, HERO_COLLECTIONS } from '../../../../constant/constant';
import { get } from 'lodash';
import { minimizeAddress } from '../../../../utils/string';
import { getFullDisplayBalance } from '../../../../utils/number';

const GeneralCard = ({ data }: any) => {
    const navigate = useNavigate();
    const nftAddress = get(data, 'collection_address', '');
    const nftId = get(data, 'tokenId', '');
    return (
        <div onClick={() => navigate(`${APP_ROUTES.MARKET}/${nftAddress}/${nftId}`)} className="general-card">
            <div className="owner">
                <span>Owner</span>
                <OverlayTrigger overlay={<Tooltip data-bs-theme="light">{get(data, 'owner', '')}</Tooltip>}>
                    <a
                        onClick={(e) => e.stopPropagation()}
                        href={`https://bscscan.com/address/${get(data, 'owner', '')}`}
                        target="_blank"
                    >
                        {minimizeAddress(get(data, 'owner', ''))}
                    </a>
                </OverlayTrigger>
            </div>

            <div className="image">
                <Image src={get(HERO_COLLECTIONS, `[${get(data, 'attributes[0].value')}].image`, '')} alt="nft" />
            </div>

            <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="name">
                    <Image
                        src="https://marketplace.mymasterwar.com/media/images/he/icon-thuy.png?v=EHdmOdineo"
                        height={18}
                        alt="Thuy"
                    />
                    <h3>{get(HERO_COLLECTIONS, `[${get(data, 'attributes[0].value')}].name`, '')}</h3>
                </div>
                <div className="id">#{get(data, 'tokenId', '')}</div>
            </div>

            <div className="d-flex align-items-center justify-content-between">
                <div className="price">
                    <Image src="https://marketplace.mymasterwar.com/media/images/cur.png?v=zp7nfuezkA" alt="" />
                    <span>
                        {getFullDisplayBalance(get(data, 'price', 0))}{' '}
                        {CURRENCY_NAME_MAPPING[get(data, 'currency', '')]}
                    </span>
                </div>
                <div className="cat">
                    <Image
                        src="https://marketplace.mymasterwar.com/media/images/soliderType/type1.png?v=2?v=6kMJH56PsJ"
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};
export default GeneralCard;
