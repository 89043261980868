import { Web3Provider } from '@ethersproject/providers';
import { ENV_CONFIGS } from '../config';
import { ACTION_STATUS, MAX_UINT } from '../constant/constant';
import Web3 from 'web3';

import { getERC721ContractByProvider, getGameContractByProvider, getTokenContractByProvider } from '../utils/utils';
import { get } from 'lodash';

export interface IGameNFT {
    nftAddress: string;
    nftId: number;
    web3Provider: Web3Provider;
    account: string;
}

export const useActivateNft = async ({ nftAddress, nftId, web3Provider, account }: IGameNFT, callback: any) => {
    const contractAddress = ENV_CONFIGS.GAME_CONTRACT_ADDRESS;

    if (!nftAddress || nftId < 0 || !web3Provider || !account) {
        return callback({ status: ACTION_STATUS.ACTIVATE_NFT_FAIL });
    }
    let sendObject: any = { from: account, value: 1 };

    const gameContract = getGameContractByProvider(contractAddress, web3Provider);

    callback({
        status: ACTION_STATUS.ACTIVATE_NFT_SUBMITTING,
    });
    try {
        const web3 = new Web3(web3Provider.provider as any);
        const method = gameContract.methods.active(nftAddress, nftId);

        // await web3.eth.call({
        //     from: account,
        //     to: contractAddress,
        //     data: method.encodeABI(),
        // });

        return method
            .send(sendObject)
            .on('error', (error: any) => {
                console.log(error);
                callback({
                    status: ACTION_STATUS.ACTIVATE_NFT_FAIL,
                    message: get(error, 'message', ''),
                });
            })
            .then((receipt: any) => {
                if (receipt.status == true) {
                    callback({
                        status: ACTION_STATUS.ACTIVATE_NFT_SUCCESS,
                        data: receipt.transactionHash,
                    });
                } else callback({ status: ACTION_STATUS.ACTIVATE_NFT_FAIL });
            })
            .catch((err: any) => {
                console.log(err);
                callback({ status: ACTION_STATUS.ACTIVATE_NFT_FAIL, message: get(err, 'message', '') });
            });
    } catch (err: any) {
        // debugger
        callback({
            status: ACTION_STATUS.ACTIVATE_NFT_FAIL,
            message: err?.data?.message,
        });
    }
};

export const useDeactivateNft = async ({ nftAddress, nftId, web3Provider, account }: IGameNFT, callback: any) => {
    const contractAddress = ENV_CONFIGS.GAME_CONTRACT_ADDRESS;

    if (!nftAddress || nftId < 0 || !web3Provider || !account) {
        return callback({ status: ACTION_STATUS.ACTIVATE_NFT_FAIL });
    }

    let sendObject: any = { from: account, value: 2 };

    const gameContract = getGameContractByProvider(contractAddress, web3Provider);

    callback({
        status: ACTION_STATUS.DEACTIVATE_NFT_SUBMITTING,
    });
    try {
        const web3 = new Web3(web3Provider.provider as any);
        const method = gameContract.methods.deactive(nftAddress, nftId);

        // await web3.eth.call({
        //     from: account,
        //     to: contractAddress,
        //     data: method.encodeABI(),
        // });

        return method
            .send(sendObject)
            .on('error', (error: any) => {
                console.log(error);
                callback({
                    status: ACTION_STATUS.DEACTIVATE_NFT_FAIL,
                    message: get(error, 'message', ''),
                });
            })
            .then((receipt: any) => {
                if (receipt.status == true) {
                    callback({
                        status: ACTION_STATUS.DEACTIVATE_NFT_SUCCESS,
                        data: receipt.transactionHash,
                    });
                } else callback({ status: ACTION_STATUS.DEACTIVATE_NFT_FAIL });
            })
            .catch((err: any) => {
                console.log(err);
                callback({ status: ACTION_STATUS.DEACTIVATE_NFT_FAIL, message: get(err, 'message', '') });
            });
    } catch (err: any) {
        // debugger
        callback({
            status: ACTION_STATUS.DEACTIVATE_NFT_FAIL,
            message: err?.data?.message,
        });
    }
};

export interface IApproveToken {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
}

export const useGameApproveCallback = ({ currencyAddress, web3Provider, account }: IApproveToken, callback: any) => {
    const contractAddress = ENV_CONFIGS.GAME_CONTRACT_ADDRESS;

    const tokenContract = getTokenContractByProvider(currencyAddress, web3Provider, account);

    // callback({
    //     status: ACTION_STATUS.APPROVING,
    // });

    return tokenContract.methods
        .approve(contractAddress, MAX_UINT)
        .send({ from: account })
        .on('error', (error: any) => {
            console.log(error);
            callback({
                status: ACTION_STATUS.APPROVE_FAILS,
            });
        })
        .then((receipt: any) => {
            if (receipt.status == true) {
                callback({
                    status: ACTION_STATUS.APPROVED,
                });
            } else callback({ status: ACTION_STATUS.APPROVE_FAILS });
        })
        .catch((err: any) => {
            console.log(err);
            callback({ status: ACTION_STATUS.APPROVE_FAILS, message: get(err, 'data.message', '') });
        });
};

export interface IAllowanceToken {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
    contract?: string;
}

export const getAllowanceGameToken = async ({ currencyAddress, web3Provider, account }: IAllowanceToken) => {
    try {
        const tokenContract = getTokenContractByProvider(currencyAddress, web3Provider, account);

        const allocationNumber: any = await tokenContract.methods
            .allowance(account, ENV_CONFIGS.GAME_CONTRACT_ADDRESS)
            .call();
        return allocationNumber ? true : false;
    } catch (error) {
        return false;
    }
};

export interface INFTGameApproveToken {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
    tokenId: number;
}

export const useNFTGameApproveCallback = (
    { currencyAddress, web3Provider, account, tokenId }: INFTGameApproveToken,
    callback: any,
) => {
    const contractAddress = ENV_CONFIGS.GAME_CONTRACT_ADDRESS;

    const tokenContract = getERC721ContractByProvider(currencyAddress, web3Provider, account);

    // callback({
    //     status: ACTION_STATUS.APPROVING,
    // });

    console.log('asgajhsgajhs', contractAddress, tokenId);

    return tokenContract.methods
        .approve(contractAddress, tokenId)
        .send({ from: account })
        .on('error', (error: any) => {
            console.log(error);
            callback({
                status: ACTION_STATUS.APPROVE_FAILS,
            });
        })
        .then((receipt: any) => {
            if (receipt.status == true) {
                callback({
                    status: ACTION_STATUS.APPROVED,
                });
            } else callback({ status: ACTION_STATUS.APPROVE_FAILS });
        })
        .catch((err: any) => {
            console.log(err);
            callback({ status: ACTION_STATUS.APPROVE_FAILS, message: get(err, 'data.message', '') });
        });
};

export interface INFTAllowanceToken {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
    contract?: string;
    tokenId: number;
}

export const getNFTAllowanceGameToken = async ({
    currencyAddress,
    web3Provider,
    account,
    tokenId,
}: INFTAllowanceToken) => {
    try {
        const tokenContract = getERC721ContractByProvider(currencyAddress, web3Provider, account);

        const allocation: any = await tokenContract.methods.getApproved(tokenId).call();
        return allocation === ENV_CONFIGS.GAME_CONTRACT_ADDRESS ? true : false;
    } catch (error) {
        return false;
    }
};
