import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './BuyPackage.scss';
import { ACTION_STATUS, DEPOSIT_PACKAGES } from '../../../../constant/constant';
import { useWeb3React } from '@web3-react/core';
import { getAllowanceDepositToken, useDepositApproveCallback } from '../../../../hooks/useApprove';
import { ENV_CONFIGS } from '../../../../config';
import { useDeposit } from '../../../../hooks/useDeposit';
import BigNumber from 'bignumber.js';
import ProcessingModal from '../../../test/components/processing-modal/ProcessingModal';
import Button from '../../../../components/button/Button';

const BuyPackage = () => {
    const { account, provider } = useWeb3React();

    const [visible, setVisible] = useState(false);
    const [approving, setApproving] = useState(false);
    const [loading, setLoading] = useState<number>(0);
    const [hash, setHash] = useState<any>('');
    const [error, setError] = useState<string>('');

    const handleDeposit = async (index: number) => {
        const { price, id } = DEPOSIT_PACKAGES[index];
        if (!provider || !account) {
            return;
        }
        setVisible(true);
        const allowance = await getAllowanceDepositToken({
            currencyAddress: ENV_CONFIGS.USDC_ADDRESS,
            web3Provider: provider,
            account,
        });

        const bigAllowance = new BigNumber(allowance);
        if (bigAllowance.gte(price)) {
            setApproving(false);
            // package deposit
            handleDepositInternal(id);
        } else {
            setApproving(true);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useDepositApproveCallback(
                {
                    currencyAddress: ENV_CONFIGS.USDC_ADDRESS,
                    web3Provider: provider,
                    account,
                    amount: price,
                },
                (approveResult: any) => {
                    if (approveResult.status === ACTION_STATUS.APPROVED) {
                        setApproving(false);
                        setTimeout(() => {
                            handleDepositInternal(id);
                        }, 10000);
                    }
                    if (approveResult.status === ACTION_STATUS.APPROVE_FAILS) {
                        setApproving(false);
                        setLoading(-1);
                        //show alert fails
                        return;
                    }
                },
            );
        }
    };

    const handleDepositInternal = async (id: number) => {
        if (provider && account) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useDeposit(
                {
                    packageId: id,
                    currencyAddress: ENV_CONFIGS.USDC_ADDRESS,
                    web3Provider: provider,
                    account,
                },
                (result: any) => {
                    console.log('handleDeposit', result);
                    if (result.status === ACTION_STATUS.DEPOSIT_PACKAGE_SUCCESS) {
                        setLoading(1);
                        setHash(result?.data);
                    }
                    if (result.status === ACTION_STATUS.DEPOSIT_PACKAGE_FAIL) {
                        setLoading(-1);
                        setError(result?.message);
                        //show alert fails
                    }
                },
            );
        }
    };

    return (
        <div>
            <Row className="mt-3 justify-content-start  row-cols-xl-5 row-cols-lg-4 row-cols-md-3  row-cols-sm-2 gx-xl-4 gy-4 gy-xl-5">
                {DEPOSIT_PACKAGES.map((item: any, index: number) => {
                    return (
                        <Col key={'package' + 'index' + item.id}>
                            <div className="package-card">
                                <div className="price">
                                    Price: <b>{item.price} USDC</b>
                                </div>
                                <h2 className="name">
                                    <img src={item.image} />
                                </h2>
                                <div className="reward">
                                    Reward: <b>{item.reward} Diamonds</b>
                                </div>
                                <Button
                                    size="sm"
                                    className="w-100"
                                    disabled={!account}
                                    onClick={() => handleDeposit(index)}
                                >
                                    Buy
                                </Button>
                            </div>
                        </Col>
                    );
                })}
            </Row>
            <ProcessingModal
                type={'Deposit'}
                visible={visible}
                setVisible={setVisible}
                onCancel={() => {
                    setVisible(false);
                    setHash(null);
                    setLoading(0);
                    setError('');
                    setApproving(false);
                }}
                loading={loading}
                approving={approving}
                hash={hash}
                error={error}
                // retry={() => handBuyNFT(data)}
            />
        </div>
    );
};
export default BuyPackage;
