import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import './MyAccount.scss';
import { useEklBalance, useUsdcBalance } from '../../../../hooks/useState';
import { useWeb3React } from '@web3-react/core';
import { minimizeAddress } from '../../../../utils/string';
import { getFullDisplayBalance } from '../../../../utils/number';
import useWalletModal from '../../../../components/WalletConnectModal';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../../../../config';
import Button from '../../../../components/button/Button';

const MyAccount = () => {
    const { account } = useWeb3React();
    const { onPresentConnectModal } = useWalletModal();
    const usdcBal = useUsdcBalance();
    const eklBal = useEklBalance();
    const avaxBal = useEklBalance();
    const [copied, setCopied] = useState<boolean>(false);

    const handleCopyAddress = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    const handleDirect = () => {
        window.open(`${ENV_CONFIGS.EXPLORER_URL}/${EXPLORER_TYPES.ADDRESS}/${account}?chainId=${ENV_CONFIGS.CHAIN_ID}`);
    };

    return (
        <Row className="gx-xl-5 mt-4 pt-4">
            <Col xxl="4" lg="5" md="6">
                {/* <div className="account-card">
                    <div className="account-card-title">
                        <span>Overview</span>
                    </div>
                    <div className="account-card-body text-center">
                        <Row className="my-4">
                            <Col
                                xs="6"
                                lg="4"
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    src="https://marketplace.mymasterwar.com/media/img/icon-general.png"
                                    alt="Tướng"
                                    height="40px"
                                />
                                <h4 className="text-white mt-2">
                                    <b>0</b>
                                </h4>
                            </Col>
                            <Col
                                xs="6"
                                lg="4"
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    src="https://marketplace.mymasterwar.com/media/img/icon-shoes.png"
                                    alt="Trang bị"
                                    height="40px"
                                />
                                <h4 className="text-white mt-2">
                                    <b>0</b>
                                </h4>
                            </Col>
                        </Row>
                    </div>
                </div> */}

                <div className="account-card">
                    <div className="account-card-title">
                        <span>My wallet</span>
                    </div>
                    <div className="account-card-body">
                        <div className="account-info-content py-4" style={{ minHeight: 300 }}>
                            {account ? (
                                <>
                                    <div className="balance">
                                        <h5>
                                            {/* Balance:&nbsp;&nbsp;&nbsp;<b>{formatBalance(matBalance, 18, 4)} MAT</b> */}
                                            Balance:
                                        </h5>
                                        <ul>
                                            <li>
                                                <span>AVAX:</span> <b>{getFullDisplayBalance(avaxBal)}</b> AVAX
                                            </li>
                                            <li>
                                                <span>USDC:</span> <b>{getFullDisplayBalance(usdcBal)}</b> USDC
                                            </li>
                                            <li>
                                                <span>EKL:</span> <b>{getFullDisplayBalance(eklBal)}</b> EKL
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="address-link">
                                        <input
                                            type="text"
                                            className="address-input"
                                            // value={formatTransactionHash(account, 8, 16)}
                                            value={minimizeAddress(account)}
                                            readOnly
                                        />
                                        <div className="btns">
                                            <CopyToClipboard text={account} onCopy={() => handleCopyAddress()}>
                                                <Button size="sm">
                                                    <i className={copied ? 'fas fa-check' : 'far fa-copy'}></i>
                                                    {copied ? (
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M14.33 2.22L21.33 9.22C21.4535 9.36781 21.5145 9.55794 21.5 9.75V15.11C21.5003 17.7007 19.4205 19.8117 16.83 19.85C16.1677 21.4836 14.5925 22.5626 12.83 22.59H7.38C4.96489 22.59 3.00551 20.6351 3 18.22V9.14C3.0055 6.72327 4.96327 4.7655 7.38 4.76H7.81C8.57931 3.08662 10.2483 2.01037 12.09 2H13.8C13.9988 2.00018 14.1895 2.07931 14.33 2.22ZM18.96 8.93L14.69 4.66V7.76C14.6954 8.40391 15.2161 8.92457 15.86 8.93H18.96ZM12.83 21.09H7.38C5.79332 21.09 4.50551 19.8067 4.5 18.22V9.14C4.5 7.54942 5.78942 6.26 7.38 6.26V15.11C7.38 17.7334 9.50665 19.86 12.13 19.86H15.23C14.6832 20.6424 13.7844 21.103 12.83 21.09ZM12.09 18.36H16.79C18.5849 18.36 20.04 16.9049 20.04 15.11V10.58H15.81C14.2548 10.5745 12.9955 9.31517 12.99 7.76V3.5H12.09C11.2237 3.50264 10.394 3.85002 9.7842 4.46543C9.17442 5.08084 8.83468 5.91366 8.84 6.78V15.11C8.84549 16.9026 10.2974 18.3545 12.09 18.36Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M14.33 2.22L21.33 9.22C21.4535 9.36781 21.5145 9.55794 21.5 9.75V15.11C21.5003 17.7007 19.4205 19.8117 16.83 19.85C16.1677 21.4836 14.5925 22.5626 12.83 22.59H7.38C4.96489 22.59 3.00551 20.6351 3 18.22V9.14C3.0055 6.72327 4.96327 4.7655 7.38 4.76H7.81C8.57931 3.08662 10.2483 2.01037 12.09 2H13.8C13.9988 2.00018 14.1895 2.07931 14.33 2.22ZM18.96 8.93L14.69 4.66V7.76C14.6954 8.40391 15.2161 8.92457 15.86 8.93H18.96ZM12.83 21.09H7.38C5.79332 21.09 4.50551 19.8067 4.5 18.22V9.14C4.5 7.54942 5.78942 6.26 7.38 6.26V15.11C7.38 17.7334 9.50665 19.86 12.13 19.86H15.23C14.6832 20.6424 13.7844 21.103 12.83 21.09ZM12.09 18.36H16.79C18.5849 18.36 20.04 16.9049 20.04 15.11V10.58H15.81C14.2548 10.5745 12.9955 9.31517 12.99 7.76V3.5H12.09C11.2237 3.50264 10.394 3.85002 9.7842 4.46543C9.17442 5.08084 8.83468 5.91366 8.84 6.78V15.11C8.84549 16.9026 10.2974 18.3545 12.09 18.36Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    )}
                                                </Button>
                                            </CopyToClipboard>
                                            <Button variant="warning" onClick={handleDirect} size="sm">
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M20.44 3.46C20.3213 3.18364 20.0508 3.00329 19.75 3H14.75C14.3358 3 14 3.33579 14 3.75C14 4.16421 14.3358 4.5 14.75 4.5H17.94L12.22 10.22C11.9275 10.5128 11.9275 10.9872 12.22 11.28C12.5128 11.5725 12.9872 11.5725 13.28 11.28L19 5.56V8.75C19 9.16421 19.3358 9.5 19.75 9.5C20.1642 9.5 20.5 9.16421 20.5 8.75V3.75C20.4995 3.65031 20.4791 3.55172 20.44 3.46Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        d="M17.75 11C17.338 11.0054 17.0054 11.338 17 11.75V15.75C16.9945 17.5426 15.5426 18.9945 13.75 19H7.75C5.95735 18.9945 4.50549 17.5426 4.5 15.75V9.75C4.50549 7.95735 5.95735 6.50549 7.75 6.5H11.75C12.1642 6.5 12.5 6.16421 12.5 5.75C12.5 5.33579 12.1642 5 11.75 5H7.75C5.12665 5 3 7.12665 3 9.75V15.75C3 18.3734 5.12665 20.5 7.75 20.5H13.75C16.3734 20.5 18.5 18.3734 18.5 15.75V11.75C18.4946 11.338 18.162 11.0054 17.75 11Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <Button className='d-none d-lg-block w-100' onClick={onPresentConnectModal}>Connect Wallet</Button>
                            )}
                        </div>
                    </div>
                </div>
            </Col>
            <Col xxl="8" lg="7" md="6" className="mt-5 mt-md-0">
                <div className="account-card">
                    <div className="account-card-title">
                        <span>Recent activities</span>
                    </div>
                    <div className="account-card-body">
                        <div
                            className="p-4 text-center tq-no-data d-flex align-items-center justify-content-center fw-500"
                            style={{ minHeight: '300px' }}
                        >
                            No activities yet
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};
export default MyAccount;
