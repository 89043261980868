import React from 'react';
import {
    FacebookShareButton,
    InstapaperShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    InstapaperIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

interface Props {
    url: string
}

function NFTShareSection({ url }: Props) {
    return (
        <div className='text-center'>
            <small className="text-white-50">Share this:</small>
            <div className='tq-share'>
                <TelegramShareButton
                    url={url}
                    className=""
                >
                    <TelegramIcon size={26} borderRadius={8} />
                </TelegramShareButton>
                <TwitterShareButton
                    url={url}
                    hashtags={['MyMasterWar', 'MMW', 'NFT']}
                    className=""
                >
                    <TwitterIcon size={26} borderRadius={8} />
                </TwitterShareButton>
                {/* <FacebookShareButton
                    url={url}
                    quote={''}
                    hashtag={`#MyMasterWar`}
                    className=""
                > */}
                    <FacebookIcon size={26} borderRadius={8} />
                {/* </FacebookShareButton> */}
                <RedditShareButton
                    url={url}
                    className=""
                >
                    <RedditIcon size={26} borderRadius={8} />
                </RedditShareButton>
                <WhatsappShareButton
                    url={url}
                    className=""
                >
                    <WhatsappIcon size={26} borderRadius={8} />
                </WhatsappShareButton>
            </div>
        </div>
    );
}
export default NFTShareSection;
