import React, { useEffect, useState, useRef } from 'react';
import { get } from 'lodash';
import { BigNumber } from 'bignumber.js';
import Countdown from 'react-countdown';

import ProcessingModal from './ProcessingModal';
import ListingAndActiveModal from './ListingAndActiveModal';
import { useDispatch } from 'react-redux';
import ListingNFTModal from '../ListingNFTModal';
import NImage from '../NImage';
import ActiveNFTModal from '../ActiveNFTModal';
import useWalletModal from '../WalletConnectModal';
import { useWeb3React } from '@web3-react/core';
import { getFullDisplayBalance } from '../../utils/number';
import { ACTION_STATUS, CURRENCY_NAME_MAPPING, NFT_STATUS } from '../../constant/constant';
import { useAvaxBalance, useUsdcBalance } from '../../hooks/useState';
import {
    getAllowanceMarketNFT,
    getAllowanceMarketToken,
    useBuyNft,
    useListingNft,
    useMarketApproveCallback,
    useNFTMarketApproveCallback,
    useUnListingNft,
} from '../../hooks/useMarket';
import { ENV_CONFIGS } from '../../config';
import {
    getAllowanceGameToken,
    getNFTAllowanceGameToken,
    useActivateNft,
    useDeactivateNft,
    useGameApproveCallback,
    useNFTGameApproveCallback,
} from '../../hooks/useGame';
import Button from '../button/Button';
interface Props {
    data: any;
    isMine: boolean;
    setIsOwner: (val: any) => void;
    setCount: () => void;
}

function NFTTradingInfo({ data, isMine, setIsOwner, setCount }: Props) {
    const dispatch = useDispatch();
    const { onPresentConnectModal } = useWalletModal();
    const usdcBal = useUsdcBalance();
    const avaxBal = useAvaxBalance();
    const { account, chainId, provider } = useWeb3React();

    const [visible, setVisible] = useState<boolean>(false);
    const [enableBtnBuy, setEnableBtnBuy] = useState<boolean>(false);
    const [loading, setLoading] = useState<number>(0);
    const [approving, setApproving] = useState<boolean>(false);
    const [hash, setHash] = useState<any>(null);

    // listing
    const [showCountDown, setShowCountDown] = useState(true);
    const [listNftVisible, setListNftVisible] = useState(false);
    const [activeNftVisible, setActiveNftVisible] = useState(false);
    const [processingVisible, setProcessingVisible] = useState<boolean>(false);
    const [type, setType] = useState<string>('');
    const [error, setError] = useState<string>('');

    const [price, setPrice] = useState<string>('');

    // countdown onCompleted
    const onComplete = () => {
        setShowCountDown(false);
    };

    useEffect(() => {
        if (data && account && !isMine) {
            const price = get(data, 'price', 0);
            if (new BigNumber(usdcBal).gte(price)) {
                setEnableBtnBuy(true);
            } else {
                setEnableBtnBuy(false);
            }
        } else {
            setEnableBtnBuy(false);
        }
    }, [isMine, usdcBal, data, account]);

    const handleBuyNft = async (nftAddress: string, nftId: number, price: number) => {
        console.log('asjasjhgas', { nftAddress, nftId, price });
        if (!provider || !account) {
            return;
        }
        await setVisible(true);
        setType('Buying');
        setLoading(0);
        setApproving(true);
        const allowance = await getAllowanceMarketToken({
            currencyAddress: ENV_CONFIGS.USDC_ADDRESS,
            web3Provider: provider,
            account,
        });

        const bigAllowance = new BigNumber(allowance);
        console.log('bigAllowance', bigAllowance.toString());
        console.log('pricepriceprice', price);
        if (bigAllowance.gte(new BigNumber(price))) {
            setApproving(false);
            handleBuyNftInternal(nftAddress, nftId);
        } else {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useMarketApproveCallback(
                {
                    currencyAddress: ENV_CONFIGS.USDC_ADDRESS,
                    web3Provider: provider,
                    account,
                },
                (approveResult: any) => {
                    if (approveResult.status === ACTION_STATUS.APPROVED) {
                        setApproving(false);
                        setTimeout(() => {
                            handleBuyNftInternal(nftAddress, nftId);
                        }, 15000);
                    }
                    if (approveResult.status === ACTION_STATUS.APPROVE_FAILS) {
                        setApproving(false);
                        setLoading(-1);
                        //show alert fails
                        return;
                    }
                },
            );
        }
    };

    const handleBuyNftInternal = async (nftAddress: string, nftId: number) => {
        if (provider && account) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useBuyNft(
                {
                    nftAddress,
                    nftId,
                    web3Provider: provider,
                    account,
                },
                (result: any) => {
                    console.log('handleBuyNft', result);
                    if (result.status === ACTION_STATUS.BUY_NFT_SUCCESS) {
                        setTimeout(() => {
                            setCount();
                            setLoading(1);
                            setIsOwner(true);
                            //wait backend and return status for user
                            setHash(result?.data);
                        }, 12000);
                    }
                    if (result.status === ACTION_STATUS.BUY_NFT_FAIL) {
                        setLoading(-1);
                        //show alert fails
                    }
                },
            );
        }
    };
    const handleListingNftProcess = async (e: any) => {
        e.preventDefault();
        setError('');
        setListNftVisible(false);
        setVisible(true);
        setType('LISTING');
        const nftId = get(data, 'tokenId', '');
        const nftAddress = get(data, 'collection_address', '');
        if (!provider || !account) {
            return;
        }
        const allowance = await getAllowanceMarketToken({
            currencyAddress: ENV_CONFIGS.USDC_ADDRESS,
            web3Provider: provider,
            account,
        });
        const nftAllowance = await getAllowanceMarketNFT({
            currencyAddress: ENV_CONFIGS.EKL_721_ADDRESS,
            web3Provider: provider,
            account,
            tokenId: nftId,
        });
        console.log('allowance==', allowance);
        console.log('nftAllowance==', nftAllowance);
        if (!allowance) {
            setApproving(true);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useMarketApproveCallback(
                {
                    currencyAddress: ENV_CONFIGS.USDC_ADDRESS,
                    web3Provider: provider,
                    account,
                },
                async (approveResult: any) => {
                    if (approveResult.status === ACTION_STATUS.APPROVED) {
                        if (!nftAllowance) {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useNFTMarketApproveCallback(
                                {
                                    currencyAddress: ENV_CONFIGS.EKL_721_ADDRESS,
                                    web3Provider: provider,
                                    account,
                                    tokenId: nftId,
                                },
                                async (approveResult: any) => {
                                    if (approveResult.status === ACTION_STATUS.APPROVED) {
                                        setLoading(0);
                                        setApproving(false);
                                        setTimeout(() => {
                                            handleListNftInternal(nftAddress, nftId);
                                        }, 10000);
                                    } else {
                                        setApproving(false);
                                        setLoading(-1);
                                        setError(get(approveResult, 'message', ''));
                                        //show alert fails
                                        return;
                                    }
                                },
                            );
                        } else {
                            setTimeout(() => {
                                handleListNftInternal(nftAddress, nftId);
                            }, 10000);
                        }
                    } else {
                        // APPROVE_FAILS
                        setApproving(false);
                        setLoading(-1);
                        setError(get(approveResult, 'message', ''));
                        //show alert fails
                        return;
                    }
                },
            );
        } else {
            if (!nftAllowance) {
                setApproving(true);
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useNFTMarketApproveCallback(
                    {
                        currencyAddress: ENV_CONFIGS.EKL_721_ADDRESS,
                        web3Provider: provider,
                        account,
                        tokenId: nftId,
                    },
                    async (approveResult: any) => {
                        console.log('aksgajhsajsajhs', approveResult);
                        if (approveResult.status === ACTION_STATUS.APPROVED) {
                            setApproving(false);
                            setLoading(0);
                            setTimeout(() => {
                                handleListNftInternal(nftAddress, nftId);
                            }, 10000);
                        } else {
                            setApproving(false);
                            setLoading(-1);
                            setError(get(approveResult, 'message', ''));
                            //show alert fails
                            return;
                        }
                    },
                );
            } else {
                console.log('la sao ta');
                handleListNftInternal(nftAddress, nftId);
            }
        }
    };

    const handleListNftInternal = async (nftAddress: string, nftId: number) => {
        if (provider && account) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useListingNft(
                {
                    nftAddress,
                    nftId,
                    web3Provider: provider,
                    account,
                    currency: ENV_CONFIGS.USDC_ADDRESS,
                    price: Number(price),
                },
                (result: any) => {
                    console.log('handleListing', result);
                    if (result.status === ACTION_STATUS.LISTING_NFT_SUCCESS) {
                        setTimeout(() => {
                            setLoading(1);
                            setCount();
                            setHash(result.data);
                        }, 20000);
                    }
                    if (result.status === ACTION_STATUS.LISTING_NFT_FAIL) {
                        setLoading(-1);
                        //show alert fails
                    }
                },
            );
        }
    };

    const handleListingGeneralNft = async (e: any) => {
        e.stopPropagation();
        setListNftVisible(true);
    };

    const handleUnListingGeneralNft = async (e: any) => {
        e.stopPropagation();
        setType('Unlisting');
        setVisible(true);
        setError('');
        setLoading(0);

        if (!provider || !account) {
            return;
        }
        const nftId = get(data, 'tokenId', '');
        const nftAddress = get(data, 'collection_address', '');
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useUnListingNft(
            {
                nftAddress,
                nftId,
                web3Provider: provider,
                account,
            },
            (result: any) => {
                console.log('handleUnListingNft', result);
                if (result.status === ACTION_STATUS.UNLISTING_NFT_SUCCESS) {
                    setTimeout(() => {
                        setLoading(1);
                        setHash(result?.data);
                        setCount();
                    }, 20000);
                }
                if (result.status === ACTION_STATUS.UNLISTING_NFT_FAIL) {
                    setLoading(-1);
                    //show alert fails
                }
            },
        );
    };

    const handleActivate = async (e: any) => {
        e.stopPropagation();
        setType('Activate');
        const nftId = get(data, 'tokenId', '');
        const nftAddress = get(data, 'collection_address', '');
        setVisible(true);
        if (!provider || !account) {
            return;
        }
        const allowance = await getAllowanceGameToken({
            currencyAddress: ENV_CONFIGS.USDC_ADDRESS,
            web3Provider: provider,
            account,
        });
        const nftAllowance = await getNFTAllowanceGameToken({
            currencyAddress: ENV_CONFIGS.EKL_721_ADDRESS,
            web3Provider: provider,
            account,
            tokenId: nftId,
        });
        console.log('allowanceallowanceallowance', allowance);
        console.log('nftAllowancenftAllowancenftAllowance', nftAllowance);
        if (!allowance) {
            setApproving(true);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useGameApproveCallback(
                {
                    currencyAddress: ENV_CONFIGS.USDC_ADDRESS,
                    web3Provider: provider,
                    account,
                },
                async (approveResult: any) => {
                    if (approveResult.status === ACTION_STATUS.APPROVED) {
                        if (!nftAllowance) {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useNFTGameApproveCallback(
                                {
                                    currencyAddress: ENV_CONFIGS.EKL_721_ADDRESS,
                                    web3Provider: provider,
                                    account,
                                    tokenId: nftId,
                                },
                                async (approveResult: any) => {
                                    console.log('aksgajhsajsajhs', approveResult);
                                    if (approveResult.status === ACTION_STATUS.APPROVED) {
                                        setLoading(0);
                                        setApproving(false);
                                        setTimeout(() => {
                                            handleActiveNftInternal(nftAddress, nftId);
                                        }, 15000);
                                    } else {
                                        setApproving(false);
                                        setLoading(-1);
                                        setError(get(approveResult, 'message', ''));
                                        //show alert fails
                                        return;
                                    }
                                },
                            );
                        } else {
                            setTimeout(() => {
                                handleActiveNftInternal(nftAddress, nftId);
                            }, 15000);
                        }
                    } else {
                        // APPROVE_FAILS
                        setApproving(false);
                        setLoading(-1);
                        setError(get(approveResult, 'message', ''));
                        //show alert fails
                        return;
                    }
                },
            );
        } else {
            if (!nftAllowance) {
                setApproving(true);
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useNFTGameApproveCallback(
                    {
                        currencyAddress: ENV_CONFIGS.EKL_721_ADDRESS,
                        web3Provider: provider,
                        account,
                        tokenId: nftId,
                    },
                    async (approveResult: any) => {
                        if (approveResult.status === ACTION_STATUS.APPROVED) {
                            setApproving(false);
                            setLoading(0);
                            setTimeout(() => {
                                handleActiveNftInternal(nftAddress, nftId);
                            }, 15000);
                        } else {
                            setApproving(false);
                            setLoading(-1);
                            setError(get(approveResult, 'message', ''));
                            //show alert fails
                            return;
                        }
                    },
                );
            } else {
                handleActiveNftInternal(nftAddress, nftId);
            }
        }
    };

    const handleActiveNftInternal = async (nftAddress: string, nftId: number) => {
        if (provider && account) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useActivateNft(
                {
                    nftAddress,
                    nftId,
                    web3Provider: provider,
                    account,
                },
                (result: any) => {
                    console.log('handleActivate', result);
                    if (result.status === ACTION_STATUS.ACTIVATE_NFT_SUCCESS) {
                        setTimeout(() => {
                            setLoading(1);
                            setCount();
                            setHash(result.data);
                        }, 20000);
                    }
                    if (result.status === ACTION_STATUS.ACTIVATE_NFT_FAIL) {
                        setLoading(-1);
                        setError(result?.message);
                        //show alert fails
                    }
                },
            );
        }
    };

    const handleDeactivate = async (e: any) => {
        e.stopPropagation();
        setType('Deactivate');
        setVisible(true);
        setError('');
        setLoading(0);

        if (!provider || !account) {
            return;
        }
        const nftId = get(data, 'tokenId', '');
        const nftAddress = get(data, 'collection_address', '');

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useDeactivateNft(
            {
                nftAddress,
                nftId,
                web3Provider: provider,
                account,
            },
            (result: any) => {
                console.log('handleDeactivate', result);
                if (result.status === ACTION_STATUS.DEACTIVATE_NFT_SUCCESS) {
                    setTimeout(() => {
                        setLoading(1);
                        setHash(result?.data);
                        setCount();
                    }, 20000);
                }
                if (result.status === ACTION_STATUS.DEACTIVATE_NFT_FAIL) {
                    setLoading(-1);
                    setError(result?.message);
                    //show alert fails
                }
            },
        );
    };

    return (
        <>
            <div className="detail-card sale-info-card mb-5">
                <div className="detail-card-title">
                    <span>Sale info</span>
                </div>
                <div className="detail-card-body">
                    <div className="d-flex justify-content-between flex-wrap mt-4">
                        <div className="detail-info-block">
                            <div className="detail-info-title mb-0 d-flex align-items-center justify-content-between mb-2">
                                <span>ID</span>
                            </div>
                            <div onClick={() => {}} className="detail-info-value" style={{ cursor: 'pointer' }}>
                                #{get(data, 'tokenId', '') || 141}{' '}
                            </div>
                        </div>
                        <div className="detail-info-block">
                            <div className="detail-info-title mb-0 d-flex align-items-center justify-content-between mb-2">
                                <span>Price</span>
                            </div>
                            <div className="detail-info-value">
                                <div className="detail-item-price d-flex align-items-center">
                                    <NImage
                                        className="me-2"
                                        src="https://marketplace.mymasterwar.com/media/images/cur.png?v=DtZOlFGe78"
                                        alt="MAT"
                                        height={'15px'}
                                    />
                                    <div className="detail-item-price-value">
                                        {getFullDisplayBalance(get(data, 'price', 0))}{' '}
                                        {CURRENCY_NAME_MAPPING[get(data, 'currency', '')]}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="d-md-block d-none" style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
                    <div className={`mt-4 mb-2 d-md-block ${isMine} ? "" : "d-none`}>
                        {account ? (
                            get(data, 'open_time', 0) * 1000 + 10000 > Date.now() && showCountDown ? (
                                <div className="trading-countdown">
                                    <div className="trading-countdown-label">Available in</div>
                                    <button className="button btn btn-primary btn-sm w-100 w-100">
                                        <span className="tq_btn_text">
                                            <img className="me-2 mt--4px" src="/assets/media/clock.png" height="20" />
                                            <span className="fs-20">
                                                <Countdown
                                                    date={new Date(get(data, 'open_time', 0) * 1000 + 10000)}
                                                    intervalDelay={1}
                                                    precision={3}
                                                    renderer={renderCountDownOpen}
                                                    onComplete={onComplete}
                                                />
                                            </span>
                                        </span>
                                    </button>
                                    {get(data, 'nft_status', '') === NFT_STATUS.LISTING_MARKET && isMine && (
                                        <button
                                            onClick={handleUnListingGeneralNft}
                                            type="button"
                                            className="button btn btn-success btn-sm w-100 w-100 mt-3"
                                        >
                                            <span className="tq_btn_text">UNLISTING NFT</span>
                                            <div className="tq_btn_hover"></div>
                                        </button>
                                    )}
                                </div>
                            ) : !isMine ? (
                                <button
                                    disabled={!enableBtnBuy || Number(avaxBal) === 0}
                                    type="button"
                                    className="button btn btn-success btn-sm w-100 w-100"
                                    onClick={() => handleBuyNft(data?.collection_address, data?.tokenId, data?.price)}
                                >
                                    <span className="tq_btn_text">Buy Now</span>
                                    <div className="tq_btn_hover"></div>
                                </button>
                            ) : (
                                <div className="market-item-action mt-3 pb-3 d-flex align-items-center justify-content-center gap-2">
                                    {get(data, 'nft_status', '') === NFT_STATUS.LISTING_MARKET ? (
                                        <button
                                            onClick={handleUnListingGeneralNft}
                                            type="button"
                                            className="button btn btn-success btn-sm w-100 w-100"
                                        >
                                            <span className="tq_btn_text">UNLISTING NFT</span>
                                            <div className="tq_btn_hover"></div>
                                        </button>
                                    ) : get(data, 'nft_status', '') !== NFT_STATUS.ACTIVE_IN_GAME ? (
                                        <button
                                            onClick={handleListingGeneralNft}
                                            type="button"
                                            className="button btn btn-success btn-sm w-100 w-100"
                                        >
                                            <span className="tq_btn_text">LISTING NFT</span>
                                            <div className="tq_btn_hover"></div>
                                        </button>
                                    ) : null}
                                    {get(data, 'nft_status', '') !== NFT_STATUS.ACTIVE_IN_GAME &&
                                        get(data, 'nft_status', '') !== NFT_STATUS.LISTING_MARKET && (
                                            <button
                                                onClick={handleActivate}
                                                type="button"
                                                className="button btn btn-success btn-sm w-100 w-100"
                                            >
                                                <span className="tq_btn_text">ACTIVE NFT</span>
                                                <div className="tq_btn_hover"></div>
                                            </button>
                                        )}
                                    {get(data, 'nft_status', '') === NFT_STATUS.ACTIVE_IN_GAME && (
                                        <button
                                            onClick={handleDeactivate}
                                            type="button"
                                            className="button btn btn-success btn-sm w-100 w-100"
                                        >
                                            <span className="tq_btn_text">DEACTIVE NFT</span>
                                            <div className="tq_btn_hover"></div>
                                        </button>
                                    )}
                                </div>
                            )
                        ) : (
                            <Button
                                size="sm"
                                variant="warning"
                                className="d-lg-block d-none w-100"
                                onClick={onPresentConnectModal}
                            >
                                Connect Wallet
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            <ListingNFTModal
                visible={listNftVisible}
                setVisible={setListNftVisible}
                onDismiss={() => {
                    setListNftVisible(false);
                    setLoading(0);
                    setApproving(false);
                }}
                selectedNft={data}
                setLoadingVisible={setProcessingVisible}
                setLoading={setLoading}
                setApproving={setApproving}
                setHash={setHash}
                setCount={setCount}
                setType={setType}
                setError={setError}
                price={price}
                setPrice={setPrice}
                handleListingNft={handleListingNftProcess}
            />
            <ActiveNFTModal
                visible={activeNftVisible}
                setVisible={setActiveNftVisible}
                onDismiss={() => {
                    setActiveNftVisible(false);
                    setLoading(0);
                    setApproving(false);
                }}
                selectedNft={data}
                setType={setType}
                setLoadingVisible={setProcessingVisible}
                setLoading={setLoading}
                setApproving={setApproving}
                setHash={setHash}
                setCount={setCount}
                setError={setError}
            />
            <ListingAndActiveModal
                visible={processingVisible}
                setVisible={setProcessingVisible}
                loading={loading}
                onCancel={() => {
                    setProcessingVisible(false);
                    setLoading(0);
                    setApproving(false);
                }}
                approving={approving}
                hash={hash}
                type={type}
                err={error}
            />

            <ProcessingModal
                type={type}
                visible={visible}
                setVisible={setVisible}
                onCancel={() => {
                    setVisible(false);
                    setHash(null);
                    setLoading(0);
                    setApproving(false);
                }}
                loading={loading}
                approving={approving}
                hash={hash}
                // retry={() => handBuyNFT(data)}
            />
        </>
    );
}

export default NFTTradingInfo;

const renderCountDownOpen = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
        // Render a completed state
        return null;
    } else {
        if (days == 0) {
            return (
                <span className="">
                    {hours.toString().padStart(2, '0') + 'h'} : {minutes.toString().padStart(2, '0') + 'm'} :{' '}
                    {seconds.toString().padStart(2, '0') + 's'}
                </span>
            );
        } else {
            return (
                <span className="">
                    {days.toString().padStart(2, '0') + 'd'} : {hours.toString().padStart(2, '0') + 'h'} :{' '}
                    {minutes.toString().padStart(2, '0') + 'm'} : {seconds.toString().padStart(2, '0') + 's'}
                </span>
            );
        }
    }
};
