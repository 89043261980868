import { get } from 'lodash';
import React from 'react';
import NFTPropertyItem from './NFTPropertyItem';

interface Props {
    data: any;
}

function NFTInfo({ data }: Props) {
    return (
        <div className="detail-card-ttt detail-card mb-md-3 mb-1">
            <div className="detail-card-title pt-0" style={{ backgroundSize: 'auto 30px' }}>
                <span className="text-nowrap" style={{ fontSize: '20px' }}>
                    Information
                </span>
            </div>
            <div className="detail-card-body">
                <div className="detail-info-block mb-0 pb-0 pt-3">
                    <div className="detail-info-content">
                        <NFTPropertyItem
                            src="https://marketplace.mymasterwar.com/media/images/icon-phong-thu.png?v=K5weAarSec"
                            label={get(data, 'attributes[1].trait_type', '')}
                            value={get(data, 'attributes[1].value', '')}
                        />
                        <NFTPropertyItem
                            src="https://marketplace.mymasterwar.com/media/images/icon-intelligent.png?v=Deiwyk20WZ"
                            label={get(data, 'attributes[3].trait_type', '')}
                            value={get(data, 'attributes[3].value', '')}
                        />
                        <NFTPropertyItem
                            src="https://marketplace.mymasterwar.com/media/images/icon-marshal.png?v=zE924KVp60"
                            label={get(data, 'attributes[4].trait_type', '')}
                            value={get(data, 'attributes[4].value', '')}
                        />
                        <NFTPropertyItem
                            src="	https://marketplace.mymasterwar.com/media/images/icon-strength.png?v=WstYN6xaRG"
                            label={get(data, 'attributes[5].trait_type', '')}
                            value={get(data, 'attributes[5].value', '')}
                        />
                        <NFTPropertyItem
                            src="https://marketplace.mymasterwar.com/media/images/icon-expert.png?v=YAmgTP7UZa"
                            label={get(data, 'attributes[6].trait_type', '')}
                            value={get(data, 'attributes[6].value', '')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NFTInfo;
