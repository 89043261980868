import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { Container, Image, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import Button from '../components/button/Button';
import useWalletModal from '../components/WalletConnectModal';
import formatAddress from '../utils/helper';
import './AppTemplate.scss';
import { AVAX_TESTNET_CHAIN_ID } from '../libs/constants';
import ChangeNetworkModal from '../components/NFTDetailCard/ChangeNetworkModal';

const AppTemplate = () => {
    const { onPresentConnectModal } = useWalletModal();
    const { account, chainId } = useWeb3React();
    const [showMenuMobile, setShowMenuMobile] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (chainId && chainId !== AVAX_TESTNET_CHAIN_ID) {
            setVisible(true);
        }
    }, [chainId]);

    useEffect(() => {
        const handleResizeWindow = () => {
            if (window.innerWidth >= 992) {
                setShowMenuMobile(false);
                if (document.body.classList.toString().includes('overflow-hidden')) {
                    document.body.classList.remove('overflow-hidden');
                }
            }
        };
        handleResizeWindow();
        window.addEventListener('resize', handleResizeWindow);
        return () => window.removeEventListener('resize', handleResizeWindow);
    }, []);

    const toggleMenuMobile = () => {
        setShowMenuMobile(!showMenuMobile);
        if (document.body.classList.toString().includes('overflow-hidden')) {
            document.body.classList.remove('overflow-hidden');
        } else {
            document.body.classList.add('overflow-hidden');
        }
    };

    const handleMenuClick = () => {
        setShowMenuMobile(false);
        if (document.body.classList.toString().includes('overflow-hidden')) {
            document.body.classList.remove('overflow-hidden');
        }
    };

    return (
        <div className="app" data-bs-theme="dark">
            <Navbar variant="dark" fixed="top" bg="dark">
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <Image src="/logo.png" alt="" height={56} />
                    </Navbar.Brand>
                    <div className={`main-menu ${showMenuMobile ? 'show' : ''}`}>
                        <Button
                            onClick={toggleMenuMobile}
                            size="sm"
                            variant="warning"
                            className="close-menu"
                            id="btnCloseMenu"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Button>
                        <Nav>
                            <Nav.Link href="https://test.kingdoms.game/" target="_blank" onClick={handleMenuClick}>
                                Home
                            </Nav.Link>
                            <Nav.Link active as={Link} to="/" onClick={handleMenuClick}>
                                Marketplace
                            </Nav.Link>

                            {/* Mobile */}
                            <div className="d-flex flex-column gap-5 d-lg-none mt-4">
                                <Button variant="success" onClick={handleMenuClick}>
                                    Play to Airdrop
                                </Button>
                                <Button
                                    variant="primary"
                                    as="a"
                                    href="https://test.kingdoms.game/#INSTALL"
                                    target="_blank"
                                    onClick={handleMenuClick}
                                >
                                    Play game
                                </Button>
                                {account ? (
                                    <Button variant="warning" onClick={onPresentConnectModal}>
                                        {formatAddress(account)}
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Nav>
                    </div>

                    {/* Desktop */}
                    <div className="d-flex gap-3 ms-auto">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip data-bs-theme="light">Coming soon</Tooltip>}
                        >
                            <span>
                                <Button size="sm" className="d-none d-lg-block" variant="success">
                                    Play to Airdrop
                                </Button>
                            </span>
                        </OverlayTrigger>
                        <Button
                            size="sm"
                            className="d-none d-lg-block"
                            variant="primary"
                            as="a"
                            href="https://test.kingdoms.game/#INSTALL"
                            target="_blank"
                        >
                            Play game
                        </Button>
                        {account ? (
                            <>
                                <Button
                                    className="d-none d-lg-block"
                                    size="sm"
                                    variant="warning"
                                    onClick={onPresentConnectModal}
                                >
                                    {formatAddress(account)}
                                </Button>
                                <Link to="/account" className="logged-user">
                                    <img src="/assets/images/avatar.jpg" alt="" />
                                </Link>
                            </>
                        ) : (
                            <Button
                                className="d-none d-lg-block"
                                size="sm"
                                variant="warning"
                                onClick={onPresentConnectModal}
                            >
                                Connect Wallet
                            </Button>
                        )}
                    </div>
                    <Button
                        onClick={toggleMenuMobile}
                        size="sm"
                        variant="warning"
                        className="toggle-menu"
                        id="btnOpenMenu"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 7H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                            <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                            <path d="M5 17H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </Button>
                </Container>
            </Navbar>

            <Container className="py-2">
                <Outlet />
            </Container>

            {!account ? (
                <div className="button-fixed-mobile">
                    <Button variant="warning" className="w-100" onClick={onPresentConnectModal}>
                        Connect Wallet
                    </Button>
                </div>
            ) : (
                <></>
            )}
            <ChangeNetworkModal visible={visible} setVisible={setVisible} />
        </div>
    );
};
export default AppTemplate;
