import { Web3Provider } from '@ethersproject/providers';
import { ENV_CONFIGS } from '../config';
import { ACTION_STATUS, DEPOSIT_PACKAGES } from '../constant/constant';
import Web3 from 'web3';

import { getDepositContractByProvider } from '../utils/utils';
import { get } from 'lodash';

export interface IDepositPackage {
    currencyAddress: string;
    packageId: number;
    web3Provider: Web3Provider;
    account: string;
}

export const useDeposit = async (
    { packageId, currencyAddress, web3Provider, account }: IDepositPackage,
    callback: any,
) => {
    const contractAddress = ENV_CONFIGS.DEPOSIT_CONTRACT_ADDRESS;

    if (packageId < 0 || !web3Provider || !account) {
        return callback({ status: ACTION_STATUS.DEPOSIT_PACKAGE_FAIL });
    }

    let sendObject = { from: account };

    const depositContract = getDepositContractByProvider(contractAddress, web3Provider);

    callback({
        status: ACTION_STATUS.DEPOSIT_PACKAGE_SUBMITTING,
    });
    try {
        const web3 = new Web3(web3Provider.provider as any);
        const method = depositContract.methods.deposit(currencyAddress, packageId);

        await web3.eth.call({
            from: account,
            to: contractAddress,
            data: method.encodeABI(),
        });

        return method
            .send(sendObject)
            .on('error', (error: any) => {
                console.log(error);
                callback({
                    status: ACTION_STATUS.DEPOSIT_PACKAGE_FAIL,
                    message: get(error, 'message', ''),
                });
            })
            .then((receipt: any) => {
                if (receipt.status == true) {
                    callback({
                        status: ACTION_STATUS.DEPOSIT_PACKAGE_SUCCESS,
                        data: receipt.transactionHash,
                    });
                } else callback({ status: ACTION_STATUS.DEPOSIT_PACKAGE_FAIL });
            })
            .catch((err: any) => {
                console.log(err);
                callback({ status: ACTION_STATUS.DEPOSIT_PACKAGE_FAIL, message: get(err, 'data.message', '') });
            });
    } catch (err: any) {
        // debugger
        callback({
            status: ACTION_STATUS.DEPOSIT_PACKAGE_FAIL,
            message: err.message,
        });
    }
};
