import { getAddress } from '@ethersproject/address';
import { Web3Provider } from '@ethersproject/providers';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import DEPOSIT_ABI from '../constant/abi/deposit.abi.json';
import ERC20_ABI from '../constant/abi/erc20.abi.json';
import GAME_ABI from '../constant/abi/game.abi.json';
import MARKET_ABI from '../constant/abi/market.abi.json';
import ERC721_ABI from '../constant/abi/nft.abi.json';
import { ITEMS_PER_PAGE } from '../constant/constant';

export function isAddress(value: any): string | false {
    try {
        return getAddress(value);
    } catch {
        return false;
    }
}

// Get deposit contract
export function getDepositContractByProvider(address: string, library: Web3Provider) {
    const parsed = isAddress(address);
    if (!parsed) {
        throw Error(`Invalid 'address' parameter '${address}'.`);
    }
    const web3 = new Web3(library.provider as any);
    return new web3.eth.Contract(DEPOSIT_ABI as AbiItem[], address);
}

// Get game contract
export function getGameContractByProvider(address: string, library: Web3Provider) {
    const parsed = isAddress(address);
    if (!parsed) {
        throw Error(`Invalid 'address' parameter '${address}'.`);
    }
    const web3 = new Web3(library.provider as any);
    return new web3.eth.Contract(GAME_ABI as AbiItem[], address);
}

// Get market contract
export function getMarketContractByProvider(address: string, library: Web3Provider) {
    const parsed = isAddress(address);
    if (!parsed) {
        throw Error(`Invalid 'address' parameter '${address}'.`);
    }
    const web3 = new Web3(library.provider as any);
    return new web3.eth.Contract(MARKET_ABI as AbiItem[], address);
}

export function getTokenContractByProvider(tokenAddress: string, library: Web3Provider, account?: string) {
    const parsed = isAddress(tokenAddress);
    if (!parsed) {
        throw Error(`Invalid 'address' parameter '${tokenAddress}'.`);
    }
    const web3 = new Web3(library.provider as any);
    return new web3.eth.Contract(ERC20_ABI as AbiItem[], tokenAddress);
}

export function getERC721ContractByProvider(tokenAddress: string, library: Web3Provider, account?: string) {
    const parsed = isAddress(tokenAddress);
    if (!parsed) {
        throw Error(`Invalid 'address' parameter '${tokenAddress}'.`);
    }
    const web3 = new Web3(library.provider as any);
    return new web3.eth.Contract(ERC721_ABI as AbiItem[], tokenAddress);
}
export const getHeroId = (data: any[]) => {
    if (data.length === 0) return [];
    return data.map((item) => item.id);
};

export const convertFilter = (data: number[] | any) => {
    if (data.length !== 2) return { min: 0, max: 99999999 };
    return {
        min: data[0],
        max: data[1],
    };
};

export const getTotalPage = (data: number) => {
    if (!data) return 1;
    if (data % ITEMS_PER_PAGE === 0) {
      return Math.floor(data / ITEMS_PER_PAGE);
    } else {
      return Math.floor(data / ITEMS_PER_PAGE) + 1;
    }
  }