import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { BigNumber } from 'bignumber.js';
import { get, isNumber } from 'lodash';
import { useDispatch } from 'react-redux';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../../config';
import { useWeb3React } from '@web3-react/core';
import SectionTitle from '../section-title/SectionTitle';
import Button from '../button/Button';

interface ListingNFTModalProps {
    onDismiss: () => void;
    visible: boolean;
    selectedNft: any;
    setVisible: (val: boolean) => void;
    setLoading: (val: any) => void;
    setLoadingVisible: (val: boolean) => void;
    setApproving: (val: any) => void;
    setHash: (val: string) => void;
    setCount?: () => void;
    setType?: (val: string) => void;
    setError: (val: string) => void;
    price: string;
    setPrice: any;
    handleListingNft: any;
}
const ListingNFTModal = ({
    onDismiss,
    visible,
    setVisible,
    selectedNft,
    setError,
    setLoadingVisible,
    setLoading,
    setApproving,
    setHash,
    setCount,
    setType,
    price,
    setPrice,
    handleListingNft,
}: ListingNFTModalProps) => {
    const dispatch = useDispatch();
    const { account, provider } = useWeb3React();
    const [checked, setChecked] = useState<boolean>(false);
    const [currency, setCurrency] = useState<string>(ENV_CONFIGS.USDC_ADDRESS);
    const [currencyInfo, setCurrencyInfo] = useState<any>({});

    const onCurrencyChange = (val: any) => {
        console.log('value====', val);
        setCurrency(val.target.value);
    };

    const handleReset = () => {
        setPrice('');
        // setType('');
        setError('');
        setCurrency('');
        setChecked(false);
        onDismiss();
    };

    return (
        <React.Fragment>
            <Modal
                show={visible}
                id="listingNFTModal"
                className="active-nft-modal"
                centered={true}
                size="lg"
                onHide={handleReset}
                onExit={handleReset}
            >
                <Modal.Header closeButton>
                    <SectionTitle hideDivider size="sm">
                        LISTING NFT
                    </SectionTitle>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="terms-of-use">
                        <h2>Terms of use</h2>
                        <ol>
                            <li className="mb-3">
                                Users only listing NFT through the market interface. If you do not listing yourself
                                through the market, it may fail and the fee will be forfeited. We will not be
                                responsible for that.
                            </li>
                            <li className="mb-3">
                                When you list, your NFT in game will be lost. You will receive an in-game notification
                                and 50% of the resources used to upgrade the General.
                            </li>
                            <li className="mb-3 text-warning">
                                Note: if you transfer NFT between addresses, your NFT will be lost. To make the NFT
                                appear in the game, you need to buy the NFT on the market or use the "Active" function
                                (high fee)
                            </li>
                            <li className="mb-3">
                                You can check your transaction history{' '}
                                <a
                                    href={`${ENV_CONFIGS.EXPLORER_URL}/${EXPLORER_TYPES.ADDRESS}/${account}?chainId=${ENV_CONFIGS.CHAIN_ID}`}
                                    target="_blank"
                                >
                                    here
                                </a>
                                .
                            </li>
                        </ol>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-block pb-4">
                    <Row>
                        <Col md="6" className="mb-3 mb-md-0">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="chkQG1"
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                />
                                <label className="form-check-label" htmlFor="chkQG1">
                                    I agree to the terms of use
                                </label>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group mb-3">
                                <label>Price</label>
                                <div className="form-control-wrap">
                                    <input
                                        value={price}
                                        onChange={(val: any) => setPrice(val.target.value)}
                                        autoFocus={true}
                                        // min={new BigNumber(get(currencyInfo, '1', 0)).dividedBy(10 ** 18).toString()}
                                        min={0.1}
                                        type="number"
                                        className="form-control"
                                        placeholder={`Min: ${new BigNumber(get(currencyInfo, '1', 0))
                                            .dividedBy(10 ** 18)
                                            .toFixed(2)
                                            .toString()}`}
                                    />
                                    <select className="form-select" onChange={onCurrencyChange} value={currency}>
                                        <option value={ENV_CONFIGS.USDC_ADDRESS}>USDC</option>
                                        {/* <option value={''}>BNB</option> */}
                                    </select>
                                </div>
                            </div>
                            {/* <div className="form-group mb-0">
                                <label>Fee</label>
                                <span>
                                    {(get(currencyInfo, '0', 0) * 1.0) / 100}% ~{' '}
                                    {(get(currencyInfo, '0', 0) * 1.0 * parseFloat(price ? price : '0')) / 10000}
                                </span>
                            </div> */}
                            <div className="w-100 mt-4">
                                <Button
                                    size="sm"
                                    variant="warning"
                                    className="w-100"
                                    btnInnerClassName="py-2 px-5"
                                    disabled={
                                        !checked || price.trim().length === 0
                                        // new BigNumber(price).lt(
                                        //     new BigNumber(get(currencyInfo, '1', 0)).dividedBy(10 ** 18).toFixed(2),
                                        // )
                                    }
                                    onClick={handleListingNft}
                                    type="button"
                                >
                                    <span style={{ fontSize: '30px' }}>Next</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default ListingNFTModal;
