import { Col, Row } from 'react-bootstrap';
import GeneralCard from '../../../marketplace/components/general-card/GeneralCard';
import './Inventory.scss';
import { useEffect, useState } from 'react';
import { fetchUserNft } from '../../../../redux/services/market';
import { useWeb3React } from '@web3-react/core';
import { get } from 'lodash';

const Inventory = () => {
    const { account } = useWeb3React();
    const [nfts, setNfts] = useState([]);

    useEffect(() => {
        if (account) {
            fetchListOfUserNft();
        }
    }, [account]);

    const fetchListOfUserNft = async () => {
        if (account) {
            try {
                const res = await fetchUserNft(account);
                setNfts(get(res, 'data', []));
            } catch (error) {}
        }
    };

    const handleFilter = (filters: any) => {
        console.log(filters);
    };
    return (
        <Row className="gx-xl-5">
            <Col lg="12" className="mb-5 mb-lg-0">
                <div className="inventory-card">
                    <div className="inventory-card-title">
                        <b>Generals</b>
                        {/* <span>({nftData?.length || 0})</span> */}
                        <span>{nfts.length}</span>
                    </div>

                    <div className="inventory-card-body">
                        <Row className="mt-3 justify-content-start row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 gy-4 gy-xxl-5 gx-xxl-5">
                            {nfts.map((item: any, index: number) => {
                                return (
                                    <Col lg key={item?.tokenId + 'my_nft' + index}>
                                        <GeneralCard data={item} />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    );
};
export default Inventory;
