import { BigNumber } from 'bignumber.js';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NFTDetailCard from '../../components/NFTDetailCard';
import ProcessingModal from '../../components/NFTDetailCard/ProcessingModal';
import BackButton from '../../components/BackButton';
import './index.scss';
import useWalletModal from '../../components/WalletConnectModal';
import { APP_ROUTES } from '../../constant/constant';
import { fetchNftDetail } from '../../redux/services/market';
import { useWeb3React } from '@web3-react/core';
import {} from 'react-router-dom';

interface Props {}

function NFTDetail({}: Props) {
    const nftParams = useParams();
    const interval: any = useRef();
    const { onPresentConnectModal } = useWalletModal();
    const { account, chainId, provider } = useWeb3React();
    const navigate = useNavigate();
    const collectionAddress = get(nftParams, 'collectionAddress', '');
    const nftId = get(nftParams, 'nftId', '');

    const [data, setData] = useState<any>();
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<number>(0);
    const [approving, setApproving] = useState<boolean>(false);
    const [hash, setHash] = useState<any>(null);
    const [enableBtnBuy, setEnableBtnBuy] = useState<boolean>(false);
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);

    useEffect(() => {
        if (nftId && collectionAddress) {
            fetchNftInfo();
        } else {
            navigate(APP_ROUTES.MARKET);
        }
    }, [nftId, collectionAddress]);

    useEffect(() => {
        if (count) {
            fetchNftInfo();
        }
    }, [count]);

    useEffect(() => {
        if (account && data) {
            setIsOwner(get(data, 'owner', '') === account);
        }
    }, [account, data]);

    const fetchNftInfo = () => {
        fetchNftDetail({ collectionAddress, nftId })
            .then((res: any) => {
                setData(get(res, 'data', {}));
            })
            .catch((err: any) => {
                navigate(APP_ROUTES.MARKET);
            });
    };

    const handBuyNFT = async (item: any) => {};

    return (
        <React.Fragment>
            <div className="position-relative nft-detail-page">
                <div className="header">
                    <Link to="/">
                        <span>Back</span>
                    </Link>
                </div>
                <NFTDetailCard
                    data={data}
                    isMine={isOwner}
                    setCount={() => setCount((prev) => prev + 1)}
                    setIsOwner={setIsOwner}
                />
                <div className="button-fixed-mobile">
                    {account && !isOwner ? (
                        <button
                            disabled={!enableBtnBuy}
                            type="button"
                            className="button  btn btn-success btn-sm w-100"
                            onClick={() => handBuyNFT(data)}
                        >
                            <span className="tq_btn_text">Buy Now</span>
                            <div className="tq_btn_hover"></div>
                        </button>
                    ) : null}
                </div>
            </div>
            <ProcessingModal
                visible={visible}
                setVisible={setVisible}
                onCancel={() => {
                    setVisible(false);
                    setHash(null);
                    setLoading(0);
                    setApproving(false);
                }}
                loading={loading}
                approving={approving}
                hash={hash}
                // retry={() => handBuyNFT(data)}
            />
        </React.Fragment>
    );
}

export default NFTDetail;
