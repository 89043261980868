import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import OHTTooltip from '../Tooltip';

const TRADING_STATUS: string[] = ["BUY", "TRANSFER"];
interface NFTTradingHistoryProps {
    className?: string,
    data: any[]
}
function NFTTradingHistory({ className = '', data }: NFTTradingHistoryProps) {


    const [filterData, setFilterData] = useState([]);

    useEffect(() => {
        if (data) {
            const temp = data.filter(item => TRADING_STATUS.includes(item.type));
        }
    }, [data]);

    return (

        <div className={`detail-card ${className} px-0`}>
            <div className="detail-card-title">
                <span>Transactions</span>
            </div>
            <div className="detail-card-body">

                {filterData.length > 0 ?
                    <div className="table-responsive px-2" style={{ maxHeight: '220px', overflowY: 'auto' }}>
                        <table className="table detail-table mb-0 table-trade-history">
                            <thead className='text-white'>
                                <tr>
                                    <th>#</th>
                                    <th>Seller</th>
                                    <th>Buyer</th>
                                    <th className="text-end">Price</th>
                                </tr>
                            </thead>
                            <tbody className='text-white'>
                                {filterData.map((item, idx) => {
                                    return (
                                        <>
                                            <tr key={idx + 'history_item'} className='nft-infor-line'>
                                                <td className="pt-2 lh-1 pb-0 no-border">
                                                    <OHTTooltip tooltip="View Transaction">
                                                        <a target='_blank' href={``} style={{ fontSize: '13px' }}>
                                                            <i className="fas fa-eye"></i>
                                                        </a>
                                                    </OHTTooltip>
                                                </td>
                                                <td className="pt-2 lh-1 pb-0 no-border ps-0">
                                                    <a target='_blank' href={`}`}>
                                                        {}
                                                    </a>
                                                </td>
                                                <td className="pt-2 lh-1 pb-0 no-border">
                                                    <a target='_blank' href={``}>
                                                        ""
                                                    </a>
                                                </td>
                                                <td className="text-end pt-2 lh-1 pb-0 no-border">
                                                    <div className="td-main-value"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="py-0 ps-0 text-end">
                                                    <small className="text-white-50">
                                                        <i>{moment(get(item, 'createdAt', 0)).fromNow()}</i>
                                                    </small>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <div className="p-4 text-center tq-no-data" >
                            <div className="fw-500">No activities yet</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default NFTTradingHistory;
