import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from 'react-bootstrap';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../../config';
import Button from '../button/Button';

const useStyles = makeStyles((theme: any) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

interface Props {
    visible: boolean;
    setVisible: (val: any) => void;
    onCancel?: () => void;
    loading: number;
    approving?: boolean;
    hash?: string;
    error?: string;
    isSwap?: boolean;
    type?: string;
}

function ProcessingModal({
    visible,
    setVisible,
    onCancel,
    loading,
    approving,
    hash,
    error = '',
    isSwap = false,
    type,
}: Props) {
    const classes = useStyles();

    const handleDirect = () => {
        window.open(`${ENV_CONFIGS.EXPLORER_URL}/${EXPLORER_TYPES.TX}/${hash}?chainId=${ENV_CONFIGS.CHAIN_ID}`);
    };

    const handleInternalNavigate = (route: string) => {};

    return (
        <Modal centered={true} show={visible} onHide={onCancel} className="tq_modal" backdrop="static">
            <Modal.Header closeButton={loading !== 0 ? true : false}></Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h2 id="transition-modal-title">
                        {loading === 0 ? (approving ? 'Approving' : isSwap ? 'Swapping' : type) : 'Done'}
                    </h2>
                    <p id="transition-modal-description">
                        {loading === 0
                            ? 'Your request is being processed. Please wait...'
                            : loading === 1
                            ? 'Your request is submitted. View detail on AvaxScan.'
                            : 'Your request is rejected. Please try again later.'}
                    </p>
                    {loading === 0 ? (
                        <CircularProgress />
                    ) : loading === 1 ? (
                        <div>
                            <div>
                                <i className="fas fa-check text-success" style={{ fontSize: '60px' }}></i>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <i
                                    className="fas fa-exclamation-triangle text-warning"
                                    style={{ fontSize: '60px' }}
                                ></i>
                            </div>
                        </div>
                    )}
                    {loading === -1 && <p style={{ color: 'red', marginTop: 10 }}>{error}</p>}
                    {loading === 1 && (
                        <div>
                            <div style={{ cursor: 'pointer' }} onClick={handleDirect}>
                                <u>View on AvaxScan</u>
                            </div>
                            {/* <div
                                className="d-flex align-items-center justify-content-center mt-4 flex-wrap"
                                style={{ gap: '20px' }}
                            >
                                <button
                                    onClick={() => handleInternalNavigate('')}
                                    className="tq_btn tq_btn_sm tq_btn_outline tq_btn_primary py-3"
                                >
                                    Continue Buying
                                </button>
                                <button
                                    onClick={() => handleInternalNavigate('')}
                                    className="tq_btn tq_btn_sm tq_btn_outline tq_btn_outline_primary"
                                >
                                    View in Inventory
                                </button>
                            </div> */}
                        </div>
                    )}
                    {loading === -1 && (
                        <div className="mt-4">
                            <Button size="sm" variant="warning" onClick={onCancel}>
                                Close
                            </Button>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ProcessingModal;
