import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import ERC20_ABI from "../constant/abi/erc20.abi.json";
import { getContract } from "../utils/web3";

// returns null on errors
function useContract(
    address: string | undefined,
    ABI: any,
    withSignerIfPossible = true
): Contract | null {
    const { provider, account } = useWeb3React();
    return useMemo(() => {
        if (!address || !ABI || !provider) return null;
        try {
            return getContract(
                address,
                ABI,
                provider,
                withSignerIfPossible && account ? account : undefined
            );
        } catch (error) {
            console.error("Failed to get contract", error);
            return null;
        }
    }, [address, ABI, provider, withSignerIfPossible, account]);
}

export function useTokenContract(
    tokenAddress?: string,
    withSignerIfPossible?: boolean
): Contract | null {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}
