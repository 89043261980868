import React, { useState } from 'react';

interface Props {
  src: string;
  width?: string;
  height?: string;
  className?: string;
  alt?: string;
}

function NImage({ src, width, height, className, alt }: Props) {
  const internal = src && src.indexOf('http') === -1;
  const [randomVersion, setRandomVersion] = useState(1);
  return (
    <img
      src={`${internal ? `${process.env.PUBLIC_URL}/media/images/${src}` : src}?v=${randomVersion}`}
      width={width}
      height={height}
      className={className}
      alt={alt}
    />
  );
}

export default NImage;
