import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import MarketplacePage from './pages/marketplace/MarketplacePage';
import AppTemplate from './templates/AppTemplate';
import { Web3ContextProvider } from './components/evm-web3/Web3ContextProvider';
import ModalProvider from './components/WalletConnectModal/ModalContext';
import { ApplicationUpdater } from './libs/updater';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { TestPage } from './pages/test';
import NFTDetail from './pages/NFTDetailCard/NFTDetail';
import AccountPage from './pages/account/AccountPage';

function App() {
    return (
        <Provider store={store}>
            <Web3ContextProvider>
                <ModalProvider>
                    <ApplicationUpdater />
                    <BrowserRouter basename="/">
                        <Routes>
                            <Route path="/" element={<AppTemplate />}>
                                <Route index element={<MarketplacePage />} />
                                <Route path="marketplace" element={<MarketplacePage />} />
                                <Route path="marketplace/:collectionAddress/:nftId" element={<NFTDetail />} />
                                <Route path="account" element={<AccountPage />} />
                                {/* <Route path="test" element={<TestPage />} /> */}
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ModalProvider>
            </Web3ContextProvider>
        </Provider>
    );
}

export default App;
