import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { SUPPORT_SPINE_HERO } from '../../constant/constant';
// @ts-ignore
let spine: any = window.spine;

export const NFTSpine = ({ children, className, nftId }: any) => {
    const generalRef = useRef();
    const [myList, setMyList] = useState<any>({});

    useEffect(() => {
        // let nftId = 1017;
        // if (SUPPORT_SPINE_HERO.includes(dataSpine.id)) {
        //     nftId = dataSpine.id;
        // }
        if (nftId) {
            if (!SUPPORT_SPINE_HERO.includes(nftId)) {
                nftId = 1017;
            }
            if (!generalRef.current) {
                generalRef.current = new spine.SpinePlayer('player-container', {
                    jsonUrl: `/assets/media/spine-assets/Hero_${nftId}/Hero_${nftId}.json`,
                    atlasUrl: `/assets/media/spine-assets/Hero_${nftId}/Hero_${nftId}.atlas`,
                    alpha: true, // Enable player translucency
                    backgroundColor: '#00000000', // Background is fully transparent
                    premultipliedAlpha: false,
                    showControls: false,
                    viewport: {
                        x: -200,
                        y: -180,
                        // width: 400,
                        // height: parseInt(get(dataSpine, 'id', 2)) === 12 ? 542 : 482,
                    },
                    // animations: myList.animations[dataSpine.id],
                    success: function (player: any) {
                        player.skeleton.setSlotsToSetupPose();
                    },
                });
            }
        }
    }, [nftId]);

    return (
        <div id="player-container" style={{ width: '100%', height: 482 }} className={className}>
            {children}
        </div>
    );
};
