import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { BigNumber } from 'bignumber.js';
import Countdown from 'react-countdown';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import SectionTitle from '../section-title/SectionTitle';
import Button from '../button/Button';

interface ActiveNFTModalProps {
    onDismiss: () => void;
    visible: boolean;
    selectedNft: any;
    setType: (val: string) => void;
    setLoadingVisible: (val: boolean) => void;
    setVisible: (val: boolean) => void;
    setLoading: (val: any) => void;
    setApproving: (val: boolean) => void;
    setHash: (val: string) => void;
    setError: (val: string) => void;
    setCount?: () => void;
}
const ActiveNFTModal = ({
    onDismiss,
    visible,
    selectedNft,
    setType,
    setVisible,
    setLoading,
    setApproving,
    setHash,
    setCount,
    setLoadingVisible,
    setError,
}: ActiveNFTModalProps) => {
    const dispatch = useDispatch();

    const [checked, setChecked] = useState<boolean>(false);
    const [feeType, setFeeType] = useState(1);
    const [duration, setDuration] = useState(0);
    const [time, setTime] = useState<number>(0);
    const [showCountDown, setShowCountDown] = useState(false);

    const handleReset = () => {
        setChecked(false);
        onDismiss();
        // setType('');
        setError('');
        setLoading(0);
        setHash('');
        setApproving(false);
    };

    const handleActiveProgress = async () => {};

    const handleActiveNftInGame = async (e: any) => {
        e.preventDefault();
    };

    const onComplete = () => {
        setShowCountDown(false);
    };

    return (
        <React.Fragment>
            <Modal
                show={visible}
                id="activeNFTModal"
                className="active-nft-modal"
                centered={true}
                size="lg"
                onHide={handleReset}
                onExit={handleReset}
            >
                <Modal.Header closeButton>
                    <SectionTitle hideDivider size="sm">
                        ACTIVE NFT
                    </SectionTitle>
                    <Modal.Title>ACTIVE NFT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="terms-of-use">
                        <h3>Terms of use</h3>
                        <ol>
                            <li className="mb-3">
                                Users only activate NFT through the market interface. If you do not activate yourself
                                through the market, the activation may fail and the fee will be forfeited. We will not
                                be responsible for that.
                            </li>

                            <li className="mb-3">
                                When NFT is active, if NFT is active in a game account with a different associated
                                wallet address than the current wallet address, the nft in that game will be deleted,
                                and may not receive the champion upgrade resources. Be careful with your decision.
                            </li>

                            <li className="mb-3">
                                Users when active NFT will take a more fee than buying on the market, but you will not
                                face competition from other users. and this fee can be adjusted by the game publisher.
                            </li>

                            <li className="mb-3">
                                If there is any problem with listing. Please review{' '}
                                <a href={`https://docs.mymasterwar.com/`} target="_blank">
                                    our guide
                                </a>{' '}
                                or contact{' '}
                                <a href={`https://discord.com/invite/maGNny3uJQ`} target="_blank">
                                    our support
                                </a>
                                .
                            </li>

                            <li className="mb-3">
                                You can check your transaction history{' '}
                                <a href={``} target="_blank">
                                    here
                                </a>
                                .
                            </li>
                        </ol>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-block pb-4">
                    <Row>
                        <Col md="6" className="mb-3 mb-md-0">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="chkQG1"
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                />
                                <label className="form-check-label" htmlFor="chkQG1">
                                    I agree to the terms of use
                                </label>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group mb-3">
                                <label>Fee</label>
                                <span> BUSD</span>
                            </div>
                            <div className="form-group mb-0 align-items-start">
                                <label>Note</label>
                                {get(selectedNft, 'lastActiveTime', null) &&
                                new Date().getTime() - new Date(get(selectedNft, 'lastActiveTime', 0)).getTime() <
                                    duration * 1000 &&
                                showCountDown ? (
                                    <span>
                                        You just can make another activation for this nft in&nbsp;
                                        <Countdown
                                            date={new Date(time)}
                                            intervalDelay={1}
                                            precision={3}
                                            renderer={renderCountDownOpen}
                                            onComplete={onComplete}
                                        />
                                    </span>
                                ) : (
                                    <span>You are eligible to activate this nft</span>
                                )}
                            </div>
                            <div className="w-100 mt-4">
                                <Button
                                    size="sm"
                                    variant="warning"
                                    className="w-100"
                                    btnInnerClassName="py-2 px-5"
                                    disabled={!checked}
                                    onClick={handleActiveNftInGame}
                                    type="button"
                                >
                                    <span style={{ fontSize: '30px' }}>Agree</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default ActiveNFTModal;

const renderCountDownOpen = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
        // Render a completed state
        return null;
    } else {
        if (days == 0) {
            return (
                <span className="">
                    {hours.toString().padStart(2, '0') + 'h'}:{minutes.toString().padStart(2, '0') + 'm'}:
                    {seconds.toString().padStart(2, '0') + 's'}
                </span>
            );
        } else {
            return (
                <span className="">
                    {days + 'd'}:{hours.toString().padStart(2, '0') + 'h'}:{minutes.toString().padStart(2, '0') + 'm'}:
                    {seconds.toString().padStart(2, '0') + 's'}
                </span>
            );
        }
    }
};
