import { Web3Provider } from '@ethersproject/providers';
import { ENV_CONFIGS } from '../config';
import { ACTION_STATUS, MAX_UINT } from '../constant/constant';
import Web3 from 'web3';

import { getERC721ContractByProvider, getMarketContractByProvider, getTokenContractByProvider } from '../utils/utils';
import { get } from 'lodash';
import BigNumber from 'bignumber.js';

export interface IMarketBuyNFT {
    nftAddress: string;
    nftId: number;
    web3Provider: Web3Provider;
    account: string;
}

export interface IMarketListingNFT {
    nftAddress: string;
    nftId: number;
    price: number;
    currency: string;
    web3Provider: Web3Provider;
    account: string;
}

export interface IMarketUnListingNFT {
    nftAddress: string;
    nftId: number;
    web3Provider: Web3Provider;
    account: string;
}

export const useBuyNft = async ({ nftAddress, nftId, web3Provider, account }: IMarketBuyNFT, callback: any) => {
    const contractAddress = ENV_CONFIGS.MARKET_CONTRACT_ADDRESS;

    if (!nftAddress || nftId < 0 || !web3Provider || !account) {
        return callback({ status: ACTION_STATUS.BUY_NFT_FAIL });
    }

    let sendObject = { from: account };
    const marketContract = getMarketContractByProvider(contractAddress, web3Provider);

    callback({
        status: ACTION_STATUS.BUY_NFT_SUBMITTING,
    });
    try {
        const web3 = new Web3(web3Provider.provider as any);
        const method = marketContract.methods.buy(nftAddress, nftId);

        await web3.eth.call({
            from: account,
            to: contractAddress,
            data: method.encodeABI(),
        });

        return method
            .send(sendObject)
            .on('error', (error: any) => {
                console.log(error);
                callback({
                    status: ACTION_STATUS.BUY_NFT_FAIL,
                    message: get(error, 'message', ''),
                });
            })
            .then((receipt: any) => {
                if (receipt.status == true) {
                    callback({
                        status: ACTION_STATUS.BUY_NFT_SUCCESS,
                        data: receipt.transactionHash,
                    });
                } else callback({ status: ACTION_STATUS.BUY_NFT_FAIL });
            })
            .catch((err: any) => {
                console.log(err);
                callback({ status: ACTION_STATUS.BUY_NFT_FAIL, message: get(err, 'data.message', '') });
            });
    } catch (err: any) {
        // debugger
        callback({
            status: ACTION_STATUS.BUY_NFT_FAIL,
            message: err.message,
        });
    }
};

export const useListingNft = async (
    { nftAddress, nftId, web3Provider, account, price, currency }: IMarketListingNFT,
    callback: any,
) => {
    const contractAddress = ENV_CONFIGS.MARKET_CONTRACT_ADDRESS;

    if (!nftAddress || nftId < 0 || price < 0 || currency.length === 0 || !web3Provider || !account) {
        return callback({ status: ACTION_STATUS.LISTING_NFT_FAIL });
    }

    let sendObject = { from: account };
    const marketContract = getMarketContractByProvider(contractAddress, web3Provider);

    const tempPrice = new BigNumber(price).multipliedBy(10 ** 18).toNumber();

    callback({
        status: ACTION_STATUS.LISTING_NFT_SUBMITTING,
    });
    try {
        const web3 = new Web3(web3Provider.provider as any);
        const method = marketContract.methods.listing(nftAddress, nftId, currency, tempPrice);

        await web3.eth.call({
            from: account,
            to: contractAddress,
            data: method.encodeABI(),
        });

        return method
            .send(sendObject)
            .on('error', (error: any) => {
                console.log(error);
                callback({
                    status: ACTION_STATUS.LISTING_NFT_FAIL,
                    message: get(error, 'message', ''),
                });
            })
            .then((receipt: any) => {
                if (receipt.status == true) {
                    callback({
                        status: ACTION_STATUS.LISTING_NFT_SUCCESS,
                        data: receipt.transactionHash,
                    });
                } else callback({ status: ACTION_STATUS.LISTING_NFT_FAIL });
            })
            .catch((err: any) => {
                console.log(err);
                callback({ status: ACTION_STATUS.LISTING_NFT_FAIL, message: get(err, 'data.message', '') });
            });
    } catch (err: any) {
        // debugger
        callback({
            status: ACTION_STATUS.LISTING_NFT_FAIL,
            message: err.message,
        });
    }
};

export const useUnListingNft = async (
    { nftAddress, nftId, web3Provider, account }: IMarketUnListingNFT,
    callback: any,
) => {
    const contractAddress = ENV_CONFIGS.MARKET_CONTRACT_ADDRESS;

    if (!nftAddress || nftId < 0 || !web3Provider || !account) {
        return callback({ status: ACTION_STATUS.UNLISTING_NFT_FAIL });
    }

    let sendObject = { from: account };
    const marketContract = getMarketContractByProvider(contractAddress, web3Provider);

    callback({
        status: ACTION_STATUS.UNLISTING_NFT_SUBMITTING,
    });
    try {
        const web3 = new Web3(web3Provider.provider as any);
        const method = marketContract.methods.unListing(nftAddress, [nftId]);

        await web3.eth.call({
            from: account,
            to: contractAddress,
            data: method.encodeABI(),
        });

        return method
            .send(sendObject)
            .on('error', (error: any) => {
                console.log(error);
                callback({
                    status: ACTION_STATUS.UNLISTING_NFT_FAIL,
                    message: get(error, 'message', ''),
                });
            })
            .then((receipt: any) => {
                if (receipt.status == true) {
                    callback({
                        status: ACTION_STATUS.UNLISTING_NFT_SUCCESS,
                        data: receipt.transactionHash,
                    });
                } else callback({ status: ACTION_STATUS.UNLISTING_NFT_FAIL });
            })
            .catch((err: any) => {
                console.log(err);
                callback({ status: ACTION_STATUS.UNLISTING_NFT_FAIL, message: get(err, 'data.message', '') });
            });
    } catch (err: any) {
        // debugger
        callback({
            status: ACTION_STATUS.UNLISTING_NFT_FAIL,
            message: err.message,
        });
    }
};

export interface IApproveToken {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
}

export const useMarketApproveCallback = ({ currencyAddress, web3Provider, account }: IApproveToken, callback: any) => {
    const contractAddress = ENV_CONFIGS.MARKET_CONTRACT_ADDRESS;

    const tokenContract = getTokenContractByProvider(currencyAddress, web3Provider, account);

    callback({
        status: ACTION_STATUS.APPROVING,
    });

    return tokenContract.methods
        .approve(contractAddress, MAX_UINT)
        .send({ from: account })
        .on('error', (error: any) => {
            console.log(error);
            callback({
                status: ACTION_STATUS.APPROVE_FAILS,
            });
        })
        .then((receipt: any) => {
            if (receipt.status == true) {
                callback({
                    status: ACTION_STATUS.APPROVED,
                });
            } else callback({ status: ACTION_STATUS.APPROVE_FAILS });
        })
        .catch((err: any) => {
            console.log(err);
            callback({ status: ACTION_STATUS.APPROVE_FAILS, message: get(err, 'data.message', '') });
        });
};

export interface IAllowanceToken {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
    contract?: string;
}

export const getAllowanceMarketToken = async ({ currencyAddress, web3Provider, account }: IAllowanceToken) => {
    try {
        const tokenContract = getTokenContractByProvider(currencyAddress, web3Provider, account);

        const allocationNumber: BigNumber = await tokenContract.methods
            .allowance(account, ENV_CONFIGS.MARKET_CONTRACT_ADDRESS)
            .call();
        return allocationNumber.toString();
    } catch (error) {
        return '0';
    }
};

export interface IAllowanceNFT {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
    tokenId: number;
}

export const getAllowanceMarketNFT = async ({ currencyAddress, web3Provider, account, tokenId }: IAllowanceNFT) => {
    try {
        const tokenContract = getERC721ContractByProvider(currencyAddress, web3Provider, account);

        const allocation: any = await tokenContract.methods.getApproved(tokenId).call();
        console.log('asasjhagsjas', allocation);
        return allocation === ENV_CONFIGS.MARKET_CONTRACT_ADDRESS ? true : false;
    } catch (error) {
        return false;
    }
};

export interface INFTMarketApproveToken {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
    tokenId: number;
}

export const useNFTMarketApproveCallback = (
    { currencyAddress, web3Provider, account, tokenId }: INFTMarketApproveToken,
    callback: any,
) => {
    const contractAddress = ENV_CONFIGS.MARKET_CONTRACT_ADDRESS;

    const tokenContract = getERC721ContractByProvider(currencyAddress, web3Provider, account);

    // callback({
    //     status: ACTION_STATUS.APPROVING,
    // });

    return tokenContract.methods
        .approve(contractAddress, tokenId)
        .send({ from: account })
        .on('error', (error: any) => {
            console.log(error);
            callback({
                status: ACTION_STATUS.APPROVE_FAILS,
            });
        })
        .then((receipt: any) => {
            if (receipt.status == true) {
                callback({
                    status: ACTION_STATUS.APPROVED,
                });
            } else callback({ status: ACTION_STATUS.APPROVE_FAILS });
        })
        .catch((err: any) => {
            console.log(err);
            callback({ status: ACTION_STATUS.APPROVE_FAILS, message: get(err, 'data.message', '') });
        });
};

// export async function useGetCurrencyContract(
//     web3Provider: Web3Provider,
//     chainId: number,
//     currencyAddress: string,
//   ) {
//     const contractAddress = CONTRACT_EXCHANGE_V2_ADDRESS[chainId];

//     const exchangeContract = getExchangeContractByProvider(contractAddress, web3Provider);
//     return await exchangeContract.methods.getCurrency(currencyAddress).call();
//   //   return info;
//   }
