import { ENDPOINTS } from '../../constant/constant';
import { request } from '../../libs/client';

export const fetchListNftInMarket = () => {
    return request(ENDPOINTS.LIST_NFT_MARKET, { method: 'GET' });
};

export const fetchNftDetail = ({ collectionAddress, nftId }: any) => {
    return request(`${ENDPOINTS.NFT_DETAIL}/${collectionAddress}/${nftId}`, { method: 'GET' });
};

export const fetchUserNft = (account: string) => {
    return request(`${ENDPOINTS.NFT_OWNER}/${account}`, { method: 'GET' });
};

export const fetchNFTInCollection = (collectionAddress: string) => {
    return request(`${ENDPOINTS.NFT_COLLECTION}/${collectionAddress}`, { method: 'GET' });
};
