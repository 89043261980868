import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from 'react-bootstrap';
import { ENV_CONFIGS, EXPLORER_TYPES } from '../../config';
import Button from '../button/Button';
import { switchNetWork } from '../../utils/metamask';
import { AVAX_TESTNET_CHAIN_ID } from '../../libs/constants';

const useStyles = makeStyles((theme: any) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

interface Props {
    visible: boolean;
    setVisible: (val: any) => void;
}

function ChangeNetworkModal({ visible, setVisible }: Props) {
    const classes = useStyles();

    const handleSwitchNetwork = () => {
        switchNetWork(AVAX_TESTNET_CHAIN_ID);
        setVisible(false);
    };

    return (
        <Modal centered={true} show={visible} className="tq_modal" backdrop="static">
            <Modal.Header closeButton={false}></Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h2 id="transition-modal-title">Switch network</h2>
                    <p id="transition-modal-description">Please switch to Avalanche Testnet to continue</p>
                    <div className="mt-4">
                        <Button size="sm" variant="warning" onClick={handleSwitchNetwork}>
                            Switch
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ChangeNetworkModal;
