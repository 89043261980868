import { get } from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import NFTAbilities from './NFTAbilities';

import NFTImage from './NFTImage';
import NFTInfo from './NFTInfo';
import NFTTradingHistory from './NFTTradingHistory';
import NFTTradingInfo from './NFTTradingInfo';

interface Props {
    data: any;
    isMine: boolean;
    setIsOwner: (val: any) => void;
    setCount: () => void;
}

function NFTDetailCard({ data, isMine, setIsOwner, setCount }: Props) {
    const [skill, setSkill] = useState<any>(null);

    const handleSkillSelected = (skillData: any) => {
        setSkill(skillData);
    };

    return (
        <>
            <Row className="mt-lg-4">
                <Col xxl="6" xl="5">
                    <NFTImage data={data} isMine={isMine} skillData={skill} setSkillData={setSkill} />
                </Col>
                <Col xxl="6" xl="7">
                    <Row>
                        <Col md="6" className=" order-2 order-md-1">
                            <NFTInfo data={data} />
                            <NFTAbilities data={data} handleSkillSelected={handleSkillSelected} />
                        </Col>
                        <Col md="6" className="mt-5 mt-md-0 order-1 order-md-2">
                            <NFTTradingInfo setCount={setCount} setIsOwner={setIsOwner} isMine={isMine} data={data} />
                            {/* <NFTSwapInfo /> */}
                            <NFTTradingHistory className="d-md-block d-none" data={get(data, 'history', [])} />
                        </Col>
                    </Row>
                    <Row className="mt-5 mt-lg-3">
                        {/* <Col md="6" className="order-3">
                        <NFTShoesPart />
                    </Col> */}
                        <Col md="6" className="order-4"></Col>
                    </Row>
                    {/* <MintInfo className="d-block d-md-none mb-5" data={get(data, 'history', [])} /> */}
                    <NFTTradingHistory className="d-block d-md-none mb-5" data={get(data, 'history', [])} />
                </Col>
            </Row>
        </>
    );
}

export default NFTDetailCard;
