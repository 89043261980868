import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import SectionTitle from '../../components/section-title/SectionTitle';
import { fetchListNftInMarket } from '../../redux/services/market';
import GeneralCard from './components/general-card/GeneralCard';
import './MarketplacePage.scss';

const MarketplacePage = () => {
    const [nfts, setNfts] = useState([]);

    useEffect(() => {
        fetchListOfMarketNfts();
    }, []);

    const fetchListOfMarketNfts = async () => {
        try {
            const res = await fetchListNftInMarket();
            console.log('ashajsas', res);
            setNfts(get(res, 'data.items', []));
        } catch (error) {}
    };

    return (
        <div className="marketplace-page">
            <div className="marketplace-inner ">
                <div className="items">
                    <div className="filters">
                        <SectionTitle dividerInline>General</SectionTitle>
                    </div>
                    <Row className="justify-content-start row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 gy-4 gy-xxl-5 gx-xxl-5">
                        {nfts.map((item: any, index: number) => {
                            return (
                                <Col lg key={item?.tokenId + 'nft' + index}>
                                    <GeneralCard data={item} />
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </div>
        </div>
    );
};
export default MarketplacePage;
