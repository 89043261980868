import BigNumber from 'bignumber.js';
import { Web3Provider } from '@ethersproject/providers';
import { getTokenContractByProvider } from '../utils/utils';
import { ACTION_STATUS, MAX_UINT } from '../constant/constant';
import { ENV_CONFIGS } from '../config';

export interface IApproveToken {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
    amount: number;
}

export const useDepositApproveCallback = (
    { currencyAddress, web3Provider, account, amount }: IApproveToken,
    callback: any,
) => {
    const contractAddress = ENV_CONFIGS.DEPOSIT_CONTRACT_ADDRESS;

    const tokenContract = getTokenContractByProvider(currencyAddress, web3Provider, account);

    // callback({
    //     status: ACTION_STATUS.APPROVING,
    // });

    return tokenContract.methods
        .approve(contractAddress, new BigNumber(amount).multipliedBy(10 ** 18).toNumber())
        .send({ from: account })
        .on('error', (error: any) => {
            console.log(error);
            callback({
                status: ACTION_STATUS.APPROVE_FAILS,
            });
        })
        .then((receipt: any) => {
            if (receipt.status == true) {
                callback({
                    status: ACTION_STATUS.APPROVED,
                });
            } else callback({ status: ACTION_STATUS.APPROVE_FAILS });
        });
};

export interface IAllowanceToken {
    currencyAddress: string;
    web3Provider: Web3Provider;
    account: string;
    contract?: string;
}

export const getAllowanceDepositToken = async ({ currencyAddress, web3Provider, account }: IAllowanceToken) => {
    const tokenContract = getTokenContractByProvider(currencyAddress, web3Provider, account);

    const allocationNumber: any = await tokenContract.methods
        .allowance(account, ENV_CONFIGS.DEPOSIT_CONTRACT_ADDRESS)
        .call();
    return new BigNumber(allocationNumber.toString()).dividedBy(10 ** 18).toString();
};
